import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DocumentMeta from 'react-document-meta';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default class Web_development extends Component {

  constructor(props) {
    super(props)

  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
   }

  render() {
    const meta = {
      title: 'Web Application Development Services | Web Application Development Company India | Espsofttech',
      description: 'Espsofttech is a multi-grant winning web organization situated in India with workplaces in Indore, Raipur and US. Look at Web Development Services!',
      canonical: 'https://espsofttech.com/Web_development',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: ' Web application development. Web development services web development company in India'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}>
        <div id="page" className="site">
          <div id="particles-js" />
          <Header />
          <div id="content" className="site-content">
            <section className='web-background all-bg'>
              <div className='container-fluid p-0'>
                <div class="elementor-background-overlay"></div>
                <div className='portfolio-head'>
                  <h1>Web Development</h1>
                  <p>Helping Businesses build secure and scalable Web Applications, Portals and Solutions.</p>
                </div>
              </div>
            </section>
            <section className="service-page pt-5 webdevelopment">
              <div className="container">
                <div className="space-55" />
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                      <div className="icon-main web"><img src="images/icon/l1.png" className="lamp-icons-technologies" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">LAMP
                          Development</h5>
                        <p className='transform'>Our LAMP developers/programmers have developed many LAMP solutions for different industries including e-commerce, retail, and hospitality. </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                      <div className="icon-main web"><img src="images/icon/net.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">.NET
                          Development</h5>
                        <p className='transform'>We are creating .NET applications for any kind of business, starting from a small-sized company to huge enterprises. We provide full-cycle .NET development services by leveraging our expertise in the delivery of high-performance, user-centric applications for web, cloud, and mobile.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                      <div className="icon-main web"><img src="images/icon/Angular.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">Angular JS
                          Development</h5>
                        <p className="transform">The deep and broadened experience of Angular developers into HTML benefits them to leverage the simplified component base framework completely and develop a web application with declarative UI and modular structure</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                      <div className="icon-main web"><img src="images/icon/l4.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">C++
                          Development</h5>
                        <p className="transform">Espsofttech is fuelled by years of experience in providing industry-best C++ application development services to its global clients. Our team best development practices and coding standards to create reliable, scalable and maintainable applications to meet your needs.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg6 text-center ">
                      <div className="icon-main web"><img src="images/icon/l5.png" className="lara-images" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform"> Laravel
                          Development</h5>
                        <p className="transform">At Espsofttech, we work closely with our clients to create robust, scalable, aesthetically appealing, and feature-rich web applications to help them build a stronger online presence.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg hbg text-center">
                      <div className="icon-main web"><img src="images/icon/node.png" className="ruby-icons node-icon" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">Node JS
                          Development</h5>
                        <p className="transform">Espsofttech is the leading Node.js development company in India, we build and streamline your business with fast, secure, and real-time streaming Node js applications.\</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg hbg text-center">
                      <div className="icon-main web"><img src="images/icon/l7.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform"> Magento
                          Development</h5>
                        <p className="transform">Expect highly robust, scalable, seamless, and comprehensive  Magento e-commerce development services with expert Magento brains.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg hbg text-center">
                      <div className="icon-main web"><img src="images/icon/React.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">React JS
                          Development</h5>
                        <p className="transform">React Js web applications are extremely efficient & highly secure, with future-ready, easy to upgrade capabilities. You will get the perfect solution-oriented team to work with you on your project.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg hbg text-center">
                      <div className="icon-main web"><img src="images/icon/l6.png" alt="" /></div>
                      <div className="content-box">
                        <h5 className="transform">Wordpress
                          Development</h5>
                        <p className="transform">Espsofttech has been successfully providing WordPress website solutions and continuously upgrading its skills to better serve client needs. With over 10+ years of experience.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
           
          </div>
          <Footer />
        </div>
        </DocumentMeta>
      </>

    )
  }
};