import React, { useState, useEffect } from 'react'
import config from '../config/config'
import Particles from 'react-tsparticles'
import Header from '../documnets/Header'
import Footer from '../documnets/Footer'
import Modal from 'react-bootstrap/Modal';
const Home = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [announce, setShowAnnounce] = useState(true);
    const handleCloseAnnounce = async () => {
        setShowAnnounce(false);
    }
    useEffect(() => {
   
      console.log("hiii, useEffect");
      setInterval(() => {
                 setDialogOpen(true)
               
             }, 100);
     },[])
     

  return (
    <>

      {/* {isDialogOpen == true ? <Modal id="myModal" size='lg' show={announce} className="announce_modal" dialogClassName="modal-90w" onHide={handleCloseAnnounce}
        aria-labelledby="example-custom-modal-styling-title" centered>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='announcement'>
            <div className='announcement-box'>

              <div class="split"></div>

              <div class="right">

                <div id="box" className='verticalslide'>
                  <div className='announcement-slider'>
                    <div className="mrmint mt-5">
                      <div className="hero-text pt-3">
                        <h1 className="exhibit">WE ARE EXHIBITING AT WORLD BLOCKCHAIN SUBMMIT 2023</h1>
                      </div>
                      <div className="schedule-meet">
                        <h2>2nd to 5th AUGUST 2023 </h2>
                        <h3>At Singapore</h3>
                        <h3>Schedule a Meeting Now!</h3>
                        <div className="mb-3">
                          <a href="https://www.mrmint.io/wbs_singapore_2023" target="_blank" className="btn btn-primary " type="button">Click here</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div > */}
          {/* <div className="bgimg-mrmint">
                        </div>
                      
                            <div className="mrmint mt-5">
                                <div className="hero-text pt-3">
                                    <h1 className="exhibit">WE ARE EXHIBITING AT WORLD BLOCKCHAIN SUBMMIT 2023</h1>

                                </div>
                                <div className="schedule-meet">

                                    <h2>1st to 5th AUGUST 2023 </h2>
                                    <h3>At Singapore</h3>
                                    <h3>Schedule a Meeting Now!</h3>
                                </div>
                            </div> */}

        {/* </Modal.Body>
      </Modal>
        : ""} */}
 {/ ---------Singapore Modal---------- /}
            {isDialogOpen == true ? <Modal id="myModal" size='lg' show={announce} className="singapore_modal" dialogClassName="modal-90w" onHide={handleCloseAnnounce}
                aria-labelledby="example-custom-modal-styling-title" centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className='singaporeContent'>
                        <div className='singapore-box'>
                            <a href='https://www.mrmint.io/dubai/' target='_blank' rel="noreferrer">
                                <img src="images/dubai2023.png" />
                            </a>
                        </div>
                    </div >
                </Modal.Body>
            </Modal> : ""}
       


      <div>
        <div id="page" className="site">
          <Header />
          <div id="content" className="site-content">
            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n               .home_man_banner span.h1 {\n    display: inline-block;\n    font-size: 52px;\n    font-weight: 700;\n    line-height: 58px;\n    margin-bottom: 25px;\n}\n.home_man_banner .h1 span {\n    color: #eb463f;\n    line-height: 50px;\n    display: inline-block;\n}\n            " }} />
            <div id="particles-js"></div>
            <br />
          </div>
        </div>
        <section className='mainbnr mb-4'>
          <div className='container'>
            <Particles />
            <div className='row mt-5'>
              <div className='col-sm-6'>
                <div className='bnrText'>
                  <h1>Blockchain22 Development Company</h1>
                  <p className='paragraph-one'>we are in the business of developing world quality blockchain-based development services that are readily accessible and highly affordable. Get in touch with us and enrich your business with expertise. </p>
                  <h2>Welcome To Espsofttech</h2>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='bnrImg'>
                  <img src='images/blockchain.png' />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service2-v4 mt-4">
          <div className="overlay overlay-image-2" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center mb-4 mb-lg-0">
                <div className="technology-v3-left">
                  <div className="ot-heading">
                    {/* <span class="text-primary-light">Improve and Innovate with the Tech Trends</span> */}
                    <h2 className="main-heading text-white">Welcome to Espsofttech</h2>
                  </div>
                  <div className="space-5" />
                  <p className="transform">Being an Industry Leading Blockchain development company in India, We transform your business/industry processes into highly secure, scalable and transparent growth with our premium blockchain development services &amp; solutions.</p>
                  <p className="transform"> We equip businesses with the most reliable and advanced Blockchain Solutions to secure a formidable position for our
                    clients when the future unfolds</p>
                  <div className="space-20" />
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={330} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6 className>active Clients</h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 mb-md-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={850} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6 className>Projects Done</h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={25} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6 className>Team Advisors</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <a className="ot-image-box v3 st1" href="it-services.html">
                      <div className="overlay">
                        <h4>Our Mission</h4>
                      </div>
                      <img src="images/image-box1.jpg" alt="Our Mission" />
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <a className="ot-image-box v3 st2" href="web-development.html">
                      <div className="overlay">
                        <h4>Our Vision</h4>
                      </div>
                      <img src="images/image-box2.jpg" alt="Our Vision" />
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <a className="ot-image-box v3 st3" href="it-services.html">
                      <div className="overlay">
                        <h4>Our Philosophy</h4>
                      </div>
                      <img src="images/image-box3.jpg" alt="Our Philosophy" />
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <a className="ot-image-box v3 st4 mb-0" href="web-development.html">
                      <div className="overlay">
                        <h4>Our Strategy</h4>
                      </div>
                      <img src="images/image-box4.jpg" alt="Our Strategy" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="top-v3">
          <div className="container pt-0">
            <div className="row">
              <div className="col-md-12">
                <div className="top-heading">
                  <div className="ot-heading">
                    {/* <span>For Over 10 Years</span> */}
                    <div className="row">
                      <div className="col-sm-2">
                      </div>
                      <div className="col-sm-8">
                        <h2 className="ml6">
                          <span className="text-wrapper">
                            <span className="main-heading text-white">Our Services</span>
                          </span>
                        </h2>
                        <p>We are a completely bootstrapped blockchain-based software development company with 4 years of track record under our belt. </p>
                      </div>
                      <div className="col-sm-2">
                      </div>
                    </div>
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                 .ml6 {\n                                 position: relative;\n                                 font-weight: 900;\n                                 font-size: 49px;\n                                 }\n                                 .ml6 .text-wrapper {\n                                 position: relative;\n                                 display: inline-block;\n                                 padding-top: 0.2em;\n                                 padding: 0px 0px;\n                                 padding-bottom: 0;\n                                 overflow: hidden;\n                                 }\n                                 .ml6 .letter {\n                                 display: inline-block;\n                                 line-height: 80px;\n                                 font-size: 54px!important;\n                                 font-weight: 500!important;\n                                 text-transform: uppercase;\n                                 margin-bottom: 0;\n                                 }\n            " }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className=" col-lg-4 col-md-4 col-sm-6 text-center mb-4 mb-lg-0">
                <a className="tech-box v3" href="blockchain-services.html">
                  <div className="icon-main"><span className="flaticon-report-1" /></div>
                  <h5>Blockchain Development</h5>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
                <a className="tech-box v3" href="nft-development.html">
                  <div className="icon-main"><span className="flaticon-browser" /></div>
                  <h5>Nft Development</h5>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-4 text-center">
                <a className="tech-box v3" href="defi-development.html">
                  <div className="icon-main"><span className="flaticon-best" /></div>
                  <h5>Defi Development</h5>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 text-center mb-4 mb-lg-0">
                <a className="tech-box v3 sides-small" href="web-development.html">
                  <div className="icon-main"><span className="flaticon-code" /></div>
                  <h5>Web Development</h5>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 text-center mb-4 mb-lg-0">
                <a className="tech-box v3" href="mobile-development.html">
                  <div className="icon-main"><span className="flaticon-monitor" /></div>
                  <h5>Mobile App Development</h5>
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 text-center mb-4 mb-lg-0">
                <a className="tech-box v3" href="binance_smart_chain.html">
                  <div className="icon-main"><span className="flaticon-report-1" /></div>
                  <h5>Binance Smart Chain Development</h5>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-industris">
          <div className="container">
            <div className="col-md-12 text-center">
              <div className="ot-heading">
                <span> BUSINESS INDUSTRIES</span>
                <h2 className="main-heading">What We Serve</h2>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="ot-industries">
              <div className="owl-carousel owl-theme industries-inner ot-industries-slider">
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/b-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Supplychain</h6>
                      <div className="itext">
                        Our Blockchain Solutions can Optimize the Business Transactions While Adding greater visibility and efficiency across the supply chain process.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/g-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Healthcare</h6>
                      <div className="itext">
                        Acquire Blockchain Technology to Manage and Share the Clinical Trials and Medical Records across Hospitals and Medical Institutions.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/t-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Information Technology</h6>
                      <div className="itext">
                        The global economy is moving towards a new identity. In order to remain competitive and profitable, the banking and finance.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/r-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Insurance</h6>
                      <div className="itext">
                        We Deliver High-Class Blockchain Solution With Custom Smart Contract, to Automate the Insurance Process fast and Seamlessly
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/b-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>eCommerce</h6>
                      <div className="itext">
                        Helping E-Commerce Businesses with Advanced Blockchain Solution for Processing the Digital Payments Seamlessly.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/g-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Identity management</h6>
                      <div className="itext">
                        End-to-end support to secure your identity from anonymous person and procure complete control over your identity.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/t-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Information Technology</h6>
                      <div className="itext">
                        The global economy is moving towards a new identity. In order to remain competitive and profitable, the banking and finance.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/r-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Digital Record</h6>
                      <div className="itext">
                        Blockchain Solutions to Maintain the Digital Records in a Decentralized Environment to make it Secure, Reliable and Transparent.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="indus-item">
                  <div className="item-inner">
                    <div className="overlay" />
                    <div className="i-image">
                      <img src="images/r-image.png" alt="" />
                    </div>
                    <div className="iinfo">
                      <h6>Finance Management</h6>
                      <div className="itext">
                        ESP Softtech helps banks and financial institutions to manage financial services with blockchain solutions that brings in more transparency.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="clients-v5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="ot-heading">
                  <span className="text-primary-light"> our clients</span>
                  <h2 className="main-heading welcome">We are Trusted <br />15+ Countries Worldwide</h2>
                </div>
                <div className="space-35" />
              </div>
              <div className="col-lg-12">
                <div className="ot-testimonials testimonials-style-2">
                  <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                    <div className="testi-item">
                      <div className="layer1" />
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img src="images/testimonial/01.jpg" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                          <div className="tinfo">
                            <h6>Don D,</h6>
                            <span>CEO</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Rahul has been very professional and looked after the development without interruption. Best thing was communication. Rahul has always contacted me promptly and sent updates daily. Second best thing is that they have been able to fix every single issue encountered during the development. These guys are highly recommended: they work very hard, are always available, they communicate well, they look after your project and they genuinely care about 100% client satisfaction. I will definitely use them again, thank you!"
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1" />
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img src="images/testimonial/02.jpg" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                          <div className="tinfo">
                            <h6>Jeffry Ng B,</h6>
                            <span>CEO</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Espsofttech is a wonderful professional to work with. He is always very communicative, always with a fast solution when there is an issue coming up, quality is just perfect, and you can feel he controls his work and that gives you tranquility.If you are reading this, just push the button to chat with Espsofttech , you will do the right choice and will not be disappointed! Espsofttech is an excellent professional to work with. He has really good communication, patience, understanding, dedication, expertise and more qualities."
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1" />
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img src="images/testimonial/03.jpg" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                          <div className="tinfo">
                            <h6>Giulio A,</h6>
                            <span>CEO</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "ESP Softtech Pvt. Ltd is good and they will do follow up one by one to make sure your project completed to satisfaction. Definitely will find them again for more projects"
                        </div>
                      </div>
                    </div>
                    <div className="testi-item">
                      <div className="layer1" />
                      <div className="layer2">
                        <div className="t-head flex-middle">
                          <img src="images/testimonial/02.jpg" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                          <div className="tinfo">
                            <h6>Oliver F,</h6>
                            <span>CEO, Sofbox</span>
                          </div>
                        </div>
                        <div className="ttext">
                          "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-consultation">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p> Drop us a line! We are here to answer your questions 24/7</p>
                <h2>NEED A CONSULTATION?</h2>
                <div className="ot-button">
                  <a href={`${config.baseUrl}Contact`} className="octf-btn octf-btn-primary">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="padding-half">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="partners">
                  <div className="owl-carousel owl-theme home-client-carousel">
                    <div className="partners-slide mr-60px">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/cc.png" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/230x0w.png" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/6.png" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/set_logo.png" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/travel.png" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/unnamed.webp" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/Eventfully.webp" alt="" />
                        </figure>
                      </a>
                    </div>
                    <div className="partners-slide">
                      <a href="#" className="client-logo">
                        <figure className="partners-slide-inner">
                          <img className="partners-slide-image" src="images/client-logos/client2.svg" alt="" />
                        </figure>
                      </a>
                    </div>
                    {/* <div class="partners-slide">
                                 <a href="#" class="client-logo">
                                    <figure class="partners-slide-inner">
                                       <img class="partners-slide-image" src="images/client-logos/client3.svg" alt="">
                                    </figure>
                                 </a>
                              </div>
                              <div class="partners-slide">
                                 <a href="#" class="client-logo">
                                    <figure class="partners-slide-inner">
                                       <img class="partners-slide-image" src="images/client-logos/client4.png" alt="">
                                    </figure>
                                 </a>
                              </div>
                              <div class="partners-slide">
                                 <a href="#" class="client-logo">
                                    <figure class="partners-slide-inner">
                                       <img class="partners-slide-image" src="images/client-logos/client5.svg" alt="">
                                    </figure>
                                 </a>
                              </div>
                              <div class="partners-slide">
                                 <a href="#" class="client-logo">
                                    <figure class="partners-slide-inner">
                                       <img class="partners-slide-image" src="images/client-logos/client6.svg" alt="">
                                    </figure>
                                 </a>
                              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    </>
  )
}

export default Home