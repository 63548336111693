import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
export default class Faqs extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <div id="page" className="site">
          <div id="particles-js" />
          <Header />
          <div id="content" className="site-content">
            <section className="faq">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="ot-heading">
                      <h2><span>FAQ</span></h2>
                      <h4 className="main-heading text-white">Read Most Frequent Questions</h4>
                    </div>
                    <div className="space-25" />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-6">
                    <div className="ot-accordions">
                      <div className="acc-item">
                        <span className="acc-toggle">1. What are the solutions do you offer once the project gets deployed?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>We provide a complete website (or) application maintenance &amp; support from our end. Even if you need to include the new features in your projects, you can contact us. We are here to provide you the entire support.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">2. What kind of payment gateway do you accept?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>We can accept all kinds of payment processes.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">3. How do I report something if any issue occurs?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>If any issues facing in your blockchain project. Don't get panic. You can contact our blockchain project team or else reach us through our mail (or) skype.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">4. Can I directly meet you to discuss my on-going project?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>In most of the cases, we will discuss with our clients via email, skype, and mobile. By the way, if you want to meet directly you can come to our office but you have get an appointment before.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">5. Will you charge an additional cost more than you suggested before?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>No. Once, we discussed your project requirements and then quoted it accordingly. We don't charge you a single penny from our end once the final price gets quoted. If you need to add extra features to your project, we will quote you.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">6. Will it be possible to check my blockchain applications while it's in progress?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Yeah, sure. We provide you the login credentials of your project which we have hosted on our host server. You can check it out at any time and know about the progress of your project.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">7. Will you assign a dedicated project manager to our project?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Yes, we can provide a dedicated project manager for you. Our project manager updates the entire progress about your project on a daily (or) weekly basis and also you can share valuable feedback about your ongoing projects anytime.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ot-accordions">
                      <div className="acc-item">
                        <span className="acc-toggle">8. How long will you take it to build blockchain applications for Startups, Enterprises, and Industries?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>It depends upon the requirements of your project. We are following agile development methodologies that help us to deliver your projects as soon as possible.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">9. Is Blockchain the next big thing?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Yes, obviously blockchain is the next big thing. Behind all the headlines, the real story is blockchain in which transactions are recorded publicly and chronologically.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">10. What is Smart Contract?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Smart contract, so called as cryptocontract, is a computer program, that controls transferring of digital currencies or assets between the parties as per the certain conditions. This contracts are stored on the decentralized ledger, blockchain which underpins bitcoin as well as other cryptocurrencies.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">11. What is a Peer to Peer(P2P) Network?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>The P2P, Peer to Peer, in which the peers are computer systems connected to each other through internet. Files can be shared straightly between the system networks without the central server.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">12. What is Cryptography in Blockchain Technology?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Cryptography, an inherent part of blockchain’s inner working. Public key encryption acts as a rudiment for blockchain wallets &amp; transactions, whereas cryptography hash function offer immutable trait which enables blockchain transaction to be more efficient.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">13. What is Distributed Ledger?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>The distributed ledger is a database which is collectively mutual and integrated across several sites as well as geographies or institutions. It let transactions to acquire public witnesses which results in occurring of cyberattack more difficult.</p>
                        </div>
                      </div>
                      <div className="acc-item">
                        <span className="acc-toggle">14. How do you Create a Blockchain?
                          <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                        </span>
                        <div className="acc-content">
                          <p>Building blockchain solutions is done through 8 steps.</p>
                          <ul><li>Identifying the apt use-case</li><li>Determining the precise consensus mechanism</li><li>Picking the suitable platform</li><li>Designing the nodes</li><li>Plan the Blockchain Instance</li><li>Developing the API’s</li><li>Create admin and user interface</li><li>Adding future tech</li></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <p>1. What are the solutions do you offer once the project gets deployed?</p>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                          <div class="accordion-body">
                            <p>We provide a complete website (or) application maintenance & support from our end. Even if you need to include the new features in your projects, you can contact us. We are here to provide you the entire support.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <p>2. What kind of payment gateway do you accept?</p>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>We can accept all kinds of payment processes.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            3. How do I report something if any issue occurs?
                          </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>Placeholder content for this accordion, which is intended to demonstrate the class.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <p>4. Can I directly meet you to discuss my on-going project?</p>
                          </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>In most of the cases, we will discuss with our clients via email, skype, and mobile. By the way, if you want to meet directly you can come to our office but you have get an appointment before.
                              1. What are the solutions do you offer once the project gets deployed?
                              2. What kind of payment gateway do you accept?
                              3. How do I report something if any issue occurs?
                              4. Can I directly meet you to discuss my on-going project?
                              Engitech
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                  <div class="accordion" id="accordionExample1">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne1">
                          <button class="accordion-button faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                            <p>1. What are the solutions do you offer once the project gets deployed?</p>
                          </button>
                        </h2>
                        <div id="collapseOne1" class="accordion-collapse collapse show" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                          <div class="accordion-body">
                            <p>We provide a complete website (or) application maintenance & support from our end. Even if you need to include the new features in your projects, you can contact us. We are here to provide you the entire support.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo1">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                            <p>2. What kind of payment gateway do you accept?</p>
                          </button>
                        </h2>
                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
                          <div class="accordion-body">
                            <p>We can accept all kinds of payment processes.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree1">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                            3. How do I report something if any issue occurs?
                          </button>
                        </h2>
                        <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1" data-bs-parent="#accordionExample1">
                          <div class="accordion-body">
                            <p>Placeholder content for this accordion, which is intended to demonstrate the class.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour1">
                          <button class="accordion-button collapsed faq-que" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour1" aria-expanded="false" aria-controls="collapseFour1">
                            <p>4. Can I directly meet you to discuss my on-going project?</p>
                          </button>
                        </h2>
                        <div id="collapseFour1" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample1">
                          <div class="accordion-body">
                            <p>In most of the cases, we will discuss with our clients via email, skype, and mobile. By the way, if you want to meet directly you can come to our office but you have get an appointment before.
                              1. What are the solutions do you offer once the project gets deployed?
                              2. What kind of payment gateway do you accept?
                              3. How do I report something if any issue occurs?
                              4. Can I directly meet you to discuss my on-going project?
                              Engitech
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>{/* #page */}
      </>

    )
  }
};