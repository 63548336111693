import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
export default class Blockchain_service2 extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <div>
          <div id="page" className="site">
            <div id="particles-js" />
         <Header/>
            <div id="content" className="site-content">
              {/* <div class="page-header flex-middle banner-content">
                  <div class="container">
                      <div class="inner flex-middle">
                          <h2 class="page-title">Blockchain Services</h2>
                          <ul id="breadcrumbs" class="breadcrumbs none-style">
                              <li><a href="index.html">Home</a></li>
                              <li class="active">Blockchain Services</li>
                          </ul>    
                      </div>
                  </div>
              </div> */}
              <section className="service-page">
                <div className="container">
                  <div className="row">
                    {/* <div class="col-sm-4 wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                             <a class="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                                <figure class="">
                                <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="">
                                </figure>
                                <div class="globalImgInfoBox">
                                   <div class="w-100 pl-3 pr-3">
                                         <h2>Blockchain &amp; Allied Tech</h2>
                                         <p>Transforming business with Blockchain Technology</p>
                                         <button type="button" class="btn btn-common mt-3">Read More<i class="fa fa-angle-double-right"></i></button>
                                   </div>
                                </div>
                             </a>
                       </div> */}
                    <div className="col-md-6 col-sm-8 mb-4 mb-sm-0 ">
                      <div className="ot-heading mb-0">
                        {/*  <span> our service</span> */}
                        <h2 className="main-heading text-white">Blockchain Services</h2>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-4 text-sm-right align-self-end">
                      {/* <div class="ot-button">
                                  <a href="index-2.html" class="octf-btn octf-btn-primary">all sevices</a>
                              </div>*/}
                      <div className="space-10" />
                    </div>
                  </div>
                  <div className="space-55" />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                      <a className="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                        <figure className>
                          <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="" />
                        </figure>
                        <div className="globalImgInfoBox">
                          <div className="w-100 pl-3 pr-3">
                            <h2>Blockchain &amp; Allied Tech</h2>
                            <p>Transforming business with Blockchain Technology</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              {/* <section class="service-pricing" style="background-color: transparent;">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-heading">
                                  <span>// choose your plan</span>
                                  <h2 class="main-heading text-white">Flexible Pricing Plans</h2>
                              </div>
                              <div class="space-5"></div>
                              <p class="text-white">We help businesses elevate their value through custom software development,<br>product design, QA and consultancy services.</p>
                              <div class="space-20"></div>
                          </div>
                      </div>
                      <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-tablet"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Basic Plan</h4>
                                      <h2><sup>$</sup> 129.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                              <li class="active">Web Counsulting</li>
                                              <li class="active">24/7 System Monitoring</li>
                                              <li class="active">Machine and Deep Learning</li>
                                              <li>Data Quality Management</li>
                                              <li>Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-report"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Economy Plan</h4>
                                      <h2><sup>$</sup> 159.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                              <li class="active">Web Counsulting</li>
                                              <li class="active">24/7 System Monitoring</li>
                                              <li class="active">Machine and Deep Learning</li>
                                              <li class="active">Data Quality Management</li>
                                              <li>Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-ui"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Premium Plan</h4>
                                      <h2><sup>$</sup> 189.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                          <li class="active">Web Counsulting</li>
                                          <li class="active">24/7 System Monitoring</li>
                                          <li class="active">Machine and Deep Learning</li>
                                          <li class="active">Data Quality Management</li>
                                          <li class="active">Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
  
              <section class="section-consultation">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12 text-center">
                              <p>// Drop us a line! We are here to answer your questions 24/7</p>
                              <h2>NEED A CONSULTATION?</h2>
                              <div class="ot-button">
                                  <a href="contact.html" class="octf-btn octf-btn-primary">Contact Us</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
  
              <section class="service-clients bg-map-dots"> 
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-heading text-center">
                                  <span>// our clients</span>
                                  <h2 class="main-heading">We are Trusted <br>15+ Countries Worldwide</h2>
                              </div>
                          </div>
                      </div>
                      <div class="space-35"></div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-testimonials">
                                  <div class="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi2.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>SoftTech,</h6>
                                                          <span>Manager of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi1.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>Moonkle LTD,</h6>
                                                          <span>Client of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi2.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>SoftTech,</h6>
                                                          <span>Manager of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi1.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>Moonkle LTD,</h6>
                                                          <span>Client of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section> */}
              <section className="clients-v5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="ot-heading">
                        <span className="text-primary-light"> our clients</span>
                        <h2 className="main-heading text-white">We are Trusted <br />15+ Countries Worldwide</h2>
                      </div>
                      <div className="space-35" />
                    </div>
                    <div className="col-lg-12">
                      <div className="ot-testimonials testimonials-style-2">
                        <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider owl-loaded owl-drag">
                          <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1200px, 0px, 0px)', transition: 'all 0s ease 0s', width: '4800px'}}><div className="owl-item cloned"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>SoftTech,</h6>
                                        <span>Manager of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item cloned"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>Moonkle LTD,</h6>
                                        <span>Client of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item active"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>SoftTech,</h6>
                                        <span>Manager of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item active"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>Moonkle LTD,</h6>
                                        <span>Client of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>SoftTech,</h6>
                                        <span>Manager of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>Moonkle LTD,</h6>
                                        <span>Client of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item cloned"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>SoftTech,</h6>
                                        <span>Manager of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div><div className="owl-item cloned"><div className="testi-item">
                                  <div className="layer1" />
                                  <div className="layer2">
                                    <div className="t-head flex-middle">
                                      <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                      <div className="tinfo">
                                        <h6>Moonkle LTD,</h6>
                                        <span>Client of Company</span>                             
                                      </div>
                                    </div>
                                    <div className="ttext">
                                      "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                    </div>
                                  </div>
                                </div></div></div></div><div className="owl-nav"><button type="button" role="presentation" className="owl-prev"><i className="flaticon-back" /></button><button type="button" role="presentation" className="owl-next"><i className="flaticon-right-arrow-1" /></button></div><div className="owl-dots disabled" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        <Footer/>
          </div>{/* #page */}
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
        </>
      );
    }
  };