import logo from './logo.svg';
import './App.css';
import config from './config/config';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Index1 from './componets/Index'
import About from './componets/About';
import Portfolio from './componets/Portfolio';
import Blog from './componets/Blog';
import Contact from './componets/Contact';
import Our_team from './componets/Our_team';
import Faqs from './componets/Faqs';
import Web_development from './componets/Web_development';
import Nft_development from './componets/Nft_development';
import Mobile_development1 from './componets/Mobile_development1';
import Elements from './componets/Elements';
import Defi_development from './componets/Defi_development';
import Blockchain_service2 from './componets/Blockchain_service2';
import Blockchain_service from './componets/Blockchain_service';
import Binance_smartchain from './componets/Binance_smartchain';
import Home from './componets/Home';
import Portfolio2 from './componets/Portfolio2';
import Portfolio_blockchain from './componets/Portfolio_blockchain';
import Portfolio_web from './componets/Portfolio_web';
import Portfolio_mobile from './componets/Portfolio_mobile';
import Sitemap from './componets/Sitemap';
import Landingpage from './componets/Landingpage';


function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          {/* <Route path={`${config.baseUrl}`} element={<Index1 />} /> */}
          <Route path={`${config.baseUrl}`} element={<Landingpage />} />
          <Route path={`${config.baseUrl}About`} element={<About />} />
          <Route path={`${config.baseUrl}Portfolio`} element={<Portfolio />} />
          <Route path={`${config.baseUrl}Blog`} element={<Blog />} />
          <Route path={`${config.baseUrl}Contact`} element={<Contact />} />
          <Route path={`${config.baseUrl}Our_team`} element={<Our_team />} />
          <Route path={`${config.baseUrl}Faqs`} element={<Faqs />} />
          <Route path={`${config.baseUrl}Web_development`} element={<Web_development />} />
          <Route path={`${config.baseUrl}Nft_development`} element={<Nft_development />} />
          <Route path={`${config.baseUrl}Mobile_development`} element={<Mobile_development1 />} />
          <Route path={`${config.baseUrl}Elements`} element={<Elements />} />
          <Route path={`${config.baseUrl}Defi_development`} element={<Defi_development />} />
          <Route path={`${config.baseUrl}Blockchain_service2`} element={<Blockchain_service2 />} />
          <Route path={`${config.baseUrl}Blockchain_service`} element={<Blockchain_service />} />
          <Route path={`${config.baseUrl}Binance_smartchain`} element={<Binance_smartchain />} />
          <Route path={`${config.baseUrl}error_404`} element={<error_404 />} />
          <Route path={`${config.baseUrl}Home`} element={<Home />} />
          <Route path={`${config.baseUrl}Portfolio2`} element={<Portfolio2 />} />
          <Route path={`${config.baseUrl}Portfolio_blockchain`} element={<Portfolio_blockchain />} />
          <Route path={`${config.baseUrl}Portfolio_web`} element={<Portfolio_web />} />
          <Route path={`${config.baseUrl}Portfolio_mobile`} element={<Portfolio_mobile />} />
          <Route path={`${config.baseUrl}sitemap`} element={<Sitemap />} />          
          {/* <Route path={`${config.baseUrl}Particle`}   element={<Particle/>} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;