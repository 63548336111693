import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import Fade from 'react-reveal/Fade';
export default class Portfolio_blockchain extends Component {

    constructor(props) {
        super(props)
    }
    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }
    render() {
        return (
            <>
                <div>
                    <Header />
                    <section className='background all-bg'>
                        <div className='container-fluid p-0'>
                            <div class="elementor-background-overlay"></div>
                            <div className='portfolio-head'>
                                <h1>Blockchain Development</h1>
                                <p>Launch, Run, Secure, Scale, and Integrate your business with adaptive blockchain technology and become a part of a progressive business ecosystem.</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='container-fluid '>
                            {/* <div className='row'>
                            <div className='background'>
                            <div class="elementor-background-overlay"></div>
                                <div className='portfolio-head'>
                                    <h2>Blockchain development</h2>
                                    <p>Launch, Run, Secure, Scale, and Integrate your business with adaptive blockchain technology and become a part of a progressive business ecosystem.</p>
                                </div>
                            </div>
                        </div> */}
                            <div className='container mt-5 pt-5'>
                                <div className='row pt-5'>
                                    <div className='col-md-6'>
                                        <div class="overlay overlay-image-about5"></div>
                                        <Fade delay={1000} left>
                                            <div className='our-work'>
                                                <h5>Explore the world of secure decentralized applications with our Blockchain development services.</h5>
                                                <p>Our blockchain development services are aimed at providing efficient and secure solutions for clients representing different domains. With profound knowledge and experience in developing and managing decentralized solutions, we build business processes highly secure, transparent, and globally accessible.</p>
                                            </div>
                                        </Fade>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='our-img'>
                                            <Fade delay={1000} right>
                                                <img src="images/back1.jpg" className='back' alt="" />
                                                <img src="images/block-front 2.png" className='front' alt="" />
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className='col-md-12 pb-5 pt-5'>
                                        <h3 className='tab-head'>Our Projects</h3>
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Crypto Exchange</button>

                                                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">NFT Marketplace</button>

                                                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">NFT Minting</button>

                                                <button class="nav-link" id="nav-ico-tab" data-bs-toggle="tab" data-bs-target="#nav-ico" type="button" role="tab" aria-controls="nav-ico" aria-selected="false">ICO</button>

                                                {/* <button class="nav-link" id="nav-staking-tab" data-bs-toggle="tab" data-bs-target="#nav-staking" type="button" role="tab" aria-controls="nav-staking" aria-selected="false">Staking   </button> */}
                                            </div>
                                        </nav>
                                        <div className='row'>
                                            <div class="tab-content" id="nav-tabContent">
                                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <h3 className='sub-head'>Cryptocurrency Exchange Platform</h3>
                                                    <div className='row'>
                                                        {/* <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project1.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>BTC Pari</h4>
                                                                        <a href='https://btcpari.com/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                        {/* <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/skysa.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Skysa</h4>
                                                                        <a href='https://skysastock.com/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/arthbit.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Arthbit</h4>
                                                                        <a href='https://arthbit.com/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        {/* <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/purchase.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Purchase Bitcoin</h4>
                                                                        <a href='http://www.purchasebitcoin.co.uk/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/purpleswap.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Purple Swap</h4>
                                                                        <a href='https://purpleswap.io/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/platinx.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Platinx</h4>
                                                                        <a href='https://apps.apple.com/in/app/platinx/id1637866256' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div>

                                                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                    <h3 className='sub-head'>NFT Marketplace platform</h3>
                                                    <div className='row'>
                                                        
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/artfungible.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Artfungible</h4>
                                                                        <a href='https://artfungible.io/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>

                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/atujii.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Atujii </h4>
                                                                        <a href='https://apps.apple.com/ua/app/atujii/id1597234314' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>

                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/ether.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Ether Yield Farming</h4>
                                                                        <a href='https://eifi.io/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div>

                                                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                    <h3 className='sub-head'>NFT Minting Platform</h3>
                                                    <div className='row'>
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project4.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Odd Fellows NFTs</h4>
                                                                        <a href='https://oddfellowsnft.com/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        {/* <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project5.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>My NFT House Mint</h4>
                                                                        <a href='https://mynfthousemint.co.uk/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/venture.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Venture Clique</h4>
                                                                        <a href='https://ventureclique.com/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        {/* <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/booticoin.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Booty Coin NFT</h4>
                                                                        <a href='https://bootycoinnft.com/' target="_blank">Live URL</a>
                                                                        <img src='images/nft.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="nav-ico" role="tabpanel" aria-labelledby="nav-ico-tab">
                                                    <h3 className='sub-head'>ICO platform</h3>
                                                    <div className='row'>
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/project3.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Yuse Token</h4>
                                                                        <a href='https://yusetoken.io/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        {/* <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/scarlett.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Scarlett global</h4>
                                                                        <a href='https://scarlettglobal.io/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade> */}
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/likbit.png" class="" alt="LikeBit" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>LikeBit</h4>
                                                                        <a href='https://www.likebittoken.com/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/infiMultichain.png" class="" alt="LikeBit" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>INFI MultiChain</h4>
                                                                        <a href='https://infimultichain.com/exch/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/sqfcoin.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>SQF coin</h4>
                                                                        <a href='https://sqfcoin.com/' target="_blank">Live URL</a>
                                                                        <img src='images/crypto-graph.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div>

                                                {/* <div class="tab-pane fade" id="nav-staking" role="tabpanel" aria-labelledby="nav-staking-tab">
                                                    <h3 className='sub-head'>Defi platform</h3>
                                                    <div className='row'>
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/metaseer.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Metaseer</h4>
                                                                        <a href='https://metaseer.io/' target="_blank">Live URL</a>
                                                                        <img src='images/stake.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/oction.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Oction</h4>
                                                                        <a href='https://oction.io/home' target="_blank">Live URL</a>
                                                                        <img src='images/stake.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade left>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/metaland.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>Metaland metaverse</h4>
                                                                        <a href='https://metalandmetaverse.io/' target="_blank">Live URL</a>
                                                                        <img src='images/stake.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                        <Fade right>
                                                            <div className='col-md-6'>
                                                                <div className='project-box'>
                                                                    <div className='project-img'>
                                                                        <img src="images/defipro.png" class="" alt="" />
                                                                    </div>
                                                                    <div className='details'>
                                                                        <h4>DefiPro</h4>
                                                                        <a href='https://defipro.farm/defipro/' target="_blank">Live URL</a>
                                                                        <img src='images/stake.png' className='graph-img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </>
        )
    }
}