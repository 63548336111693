import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
export default class Elements extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <div>
          <div id="page" className="site">
       <Header/>
            <div id="content" className="site-content">
              <div className="page-header flex-middle">
                <div className="container">
                  <div className="inner flex-middle">
                    <h1 className="page-title">Elements</h1>
                    <ul id="breadcrumbs" className="breadcrumbs none-style">
                      <li><a href="index.html">Home</a></li>
                      <li className="active">Elements</li>
                    </ul>    
                  </div>
                </div>
              </div>
              <section className="typography">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Icon Boxes</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="icon-box-s2 s1">
                        <div className="icon-main"><span className="flaticon-code" /></div>
                        <div className="content-box">
                          <h5>Web Development</h5>
                          <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="icon-box-s2 s1">
                        <div className="icon-main"><span className="flaticon-app" /></div>
                        <div className="content-box">
                          <h5>Mobile Development</h5>
                          <p>Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="icon-box-s2 s1">
                        <div className="icon-main"><span className="flaticon-monitor" /></div>
                        <div className="content-box">
                          <h5>UI/UX Design</h5>
                          <p>Build the product you need on time with an experienced team that uses a clear and effective design process.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 text-center">
                      <div className="icon-box-s2 s2">
                        <div className="icon-main"><span className="flaticon-code" /></div>
                        <div className="content-box">
                          <h5>Web Development</h5>
                          <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center">
                      <div className="icon-box-s2 s2">
                        <div className="icon-main"><span className="flaticon-app" /></div>
                        <div className="content-box">
                          <h5>Mobile Development</h5>
                          <p>Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center">
                      <div className="icon-box-s2 s2">
                        <div className="icon-main"><span className="flaticon-monitor" /></div>
                        <div className="content-box">
                          <h5>UI/UX Design</h5>
                          <p>Build the product you need on time with an experienced team that uses a clear and effective design process.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-30">
                      <div className="icon-box-s2 s2 border-s1 bg bg1 text-center">
                        <div className="icon-main"><span className="flaticon-code" /></div>
                        <div className="content-box">
                          <h5><a href="web-development.html">Web Development</a></h5>
                          <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                      <div className="icon-box-s2 s2 border-s1 bg bg2 text-center">
                        <div className="icon-main"><span className="flaticon-app" /></div>
                        <div className="content-box">
                          <h5><a href="mobile-development.html">Mobile Development</a></h5>
                          <p>Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                      <div className="icon-box-s2 s2 border-s1 bg bg3 text-center">
                        <div className="icon-main"><span className="flaticon-monitor" /></div>
                        <div className="content-box">
                          <h5><a href="it-services.html">UI/UX Design</a></h5>
                          <p>Build the product you need on time with an experienced team that uses a clear and effective design.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-20" />
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="service-box s-box">
                        <span className="big-number">01</span>
                        <div className="icon-main color-s1"><span className="flaticon-correct" /></div>
                        <div className="content-box">
                          <h5>Machine Learning</h5>
                          <p>Support and Evolution</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="service-box s-box">
                        <span className="big-number">02</span>
                        <div className="icon-main color-s2"><span className="flaticon-correct" /></div>
                        <div className="content-box">
                          <h5>Artificial Intelligence</h5>
                          <p>Support and Evolution</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="service-box s-box">
                        <span className="big-number">03</span>
                        <div className="icon-main color-s3"><span className="flaticon-correct" /></div>
                        <div className="content-box">
                          <h5>Augmented Reality</h5>
                          <p>Support and Evolution</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="icon-box-s1">
                        <div className="icon-main"><span className="flaticon-medal" /></div>
                        <h5>Experience</h5>
                        <div className="line-box" />
                        <p>Our great team of more than 1400 software experts.</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="icon-box-s1">
                        <div className="icon-main"><span className="flaticon-gear" /></div>
                        <h5>Quick Support</h5>
                        <div className="line-box" />
                        <p>We’ll help you test bold new ideas while sharing your.</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="icon-box-s1 color-s2">
                        <div className="icon-main"><span className="flaticon-idea" /></div>
                        <h5>Best Solutions</h5>
                        <div className="line-box" />
                        <p>Our team of more than 1400 software experts.</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="icon-box-s1 color-s2">
                        <div className="icon-main"><span className="flaticon-edit-tools" /></div>
                        <h5>Unique Design</h5>
                        <div className="line-box" />
                        <p>We’ll help you test bold new ideas while sharing your.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-progress">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Progress Bars</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="et-progress">
                        <div className="ot-progress">
                          <div className="overflow">
                            <span className="pname f-left">mobile development</span>
                            <span className="ppercent f-right">70%</span>
                          </div>
                          <div className="iprogress">
                            <div className="progress-bar" data-percent="70%" />
                          </div>
                        </div>
                        <div className="ot-progress">
                          <div className="overflow">
                            <span className="pname f-left">web development</span>
                            <span className="ppercent f-right">90%</span>
                          </div>
                          <div className="iprogress">
                            <div className="progress-bar" data-percent="90%" />
                          </div>
                        </div>
                        <div className="ot-progress">
                          <div className="overflow">
                            <span className="pname f-left">ui/ux design</span>
                            <span className="ppercent f-right">60%</span>
                          </div>
                          <div className="iprogress">
                            <div className="progress-bar" data-percent="60%" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offset-xl-1 col-lg-6">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 mb-4">
                          <div className="circle-progress flex-middle" data-color="#43BAFF" data-height={10} data-size={135}>
                            <div className="inner-bar" data-percent={50}>
                              <span><span className="percent">0</span>%</span>
                            </div>
                            <h4>Mobile <br />Development</h4>        
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 mb-4">
                          <div className="circle-progress flex-middle" data-color="#43BAFF" data-height={10} data-size={135}>
                            <div className="inner-bar" data-percent={70}>
                              <span><span className="percent">0</span>%</span>
                            </div>
                            <h4>Wearable <br />Development</h4>        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-pricing">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Pricing Tables</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="ot-pricing-table">
                        <div className="icon-main"><span className="flaticon-tablet" /></div>
                        <div className="inner-table">
                          <h4 className="title-table">Basic Plan</h4>
                          <h2><sup>$</sup> 129.99</h2>
                          <span>Monthly Package</span>
                          <div className="details ">
                            <ul>
                              <li className="active">Web Counsulting</li>
                              <li className="active">24/7 System Monitoring</li>
                              <li className="active">Machine and Deep Learning</li>
                              <li>Data Quality Management</li>
                              <li>Security Management</li>
                            </ul>
                          </div>
                          <a href="#" className="octf-btn">Choose Plane</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="ot-pricing-table">
                        <div className="icon-main"><span className="flaticon-report" /></div>
                        <div className="inner-table">
                          <h4 className="title-table">Economy Plan</h4>
                          <h2><sup>$</sup> 159.99</h2>
                          <span>Monthly Package</span>
                          <div className="details ">
                            <ul>
                              <li className="active">Web Counsulting</li>
                              <li className="active">24/7 System Monitoring</li>
                              <li className="active">Machine and Deep Learning</li>
                              <li className="active">Data Quality Management</li>
                              <li>Security Management</li>
                            </ul>
                          </div>
                          <a href="#" className="octf-btn">Choose Plane</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="ot-pricing-table">
                        <div className="icon-main"><span className="flaticon-ui" /></div>
                        <div className="inner-table">
                          <h4 className="title-table">Premium Plan</h4>
                          <h2><sup>$</sup> 189.99</h2>
                          <span>Monthly Package</span>
                          <div className="details ">
                            <ul>
                              <li className="active">Web Counsulting</li>
                              <li className="active">24/7 System Monitoring</li>
                              <li className="active">Machine and Deep Learning</li>
                              <li className="active">Data Quality Management</li>
                              <li className="active">Security Management</li>
                            </ul>
                          </div>
                          <a href="#" className="octf-btn">Choose Plane</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elemnts-flip-boxed">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Flip Boxes</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="support-box">
                        <div className="inner-box">
                          <div className="overlay flex-middle">
                            <span className="number-box">01</span>
                            <div className="inner">
                              <p>Entrust full-cycle implementation of your software product to our experienced BAs, UI/UX designers, developers.</p>
                              <a href="it-services.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                            </div>
                          </div>
                          <div className="content-box">
                            <span className="number-box">01</span>
                            <h3>For Partners</h3>
                            <img src="images/support1.jpg" alt="For Partners" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="support-box">
                        <div className="inner-box">
                          <div className="overlay flex-middle">
                            <span className="number-box">02</span>
                            <div className="inner">
                              <p>Entrust full-cycle implementation of your software product to our experienced BAs, UI/UX designers, developers.</p>
                              <a href="web-development.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                            </div>
                          </div>
                          <div className="content-box">
                            <span className="number-box">02</span>
                            <h3>For Customers</h3>
                            <img src="images/support2.jpg" alt="For Customers" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="support-box">
                        <div className="inner-box">
                          <div className="overlay flex-middle">
                            <span className="number-box">03</span>
                            <div className="inner">
                              <p>Entrust full-cycle implementation of your software product to our experienced BAs, UI/UX designers, developers.</p>
                              <a href="mobile-development.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                            </div>
                          </div>
                          <div className="content-box">
                            <span className="number-box">03</span>
                            <h3>For Startups</h3>
                            <img src="images/support3.jpg" alt="For Startups" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-service">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Service Boxes</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="serv-box s1">
                        <div className="icon-main"><span className="flaticon-tablet" /></div>
                        <div className="content-box">
                          <h5>Custom Software Development</h5>
                          <div>We help businesses elevate their value through custom software development, product design, QA and consultancy.
                            <ul>
                              <li className="active"><a href="#">eCommerce solutions</a></li>
                              <li><a href="#">Gamification software</a></li>
                              <li><a href="#">Client mobile apps</a></li>
                            </ul>
                          </div>         
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                      <div className="serv-box s1">
                        <div className="icon-main"><span className="flaticon-report" /></div>
                        <div className="content-box">
                          <h5>Outsoursing Development</h5>
                          <div>We help businesses elevate their value through custom software development, product design, QA and consultancy.
                            <ul>
                              <li><a href="#">Development process</a></li>
                              <li className="active"><a href="#">Change management</a></li>
                              <li><a href="#">Application support</a></li>
                            </ul>
                          </div>         
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="serv-box s1">
                        <div className="icon-main"><span className="flaticon-ui" /></div>
                        <div className="content-box">
                          <h5>Software Product Development</h5>
                          <div>We help businesses elevate their value through custom software development, product design, QA and consultancy.
                            <ul>
                              <li><a href="#">Desktop applications</a></li>
                              <li><a href="#">Software-as-a-Service</a></li>
                              <li className="active"><a href="#">Mobile apps</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="serv-box-2 s2">
                        <span className="big-number">01</span>
                        <div className="icon-main"><span className="flaticon-tablet" /></div>
                        <div className="content-box">
                          <h5>Product Design</h5>
                          <div>Our product design service lets you prototype, test and validate your ideas.</div>
                          <a href="it-services.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="serv-box-2 s2">
                        <span className="big-number">02</span>
                        <div className="icon-main"> <span className="flaticon-ui" /></div>
                        <div className="content-box">
                          <h5>Development</h5>
                          <div>Our product design service lets you prototype, test and validate your ideas.</div>
                          <a href="web-development.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="serv-box-2 s2">
                        <span className="big-number">03</span>
                        <div className="icon-main"><span className="flaticon-report" /></div>
                        <div className="content-box">
                          <h5>Data Analytics</h5>
                          <div>Our product design service lets you prototype, test and validate your ideas.</div>
                          <a href="it-services.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="serv-box-2 s2">
                        <span className="big-number">04</span>
                        <div className="icon-main"><span className="flaticon-shield" /></div>
                        <div className="content-box">
                          <h5>Cyber Security</h5>
                          <div>Our product design service lets you prototype, test and validate your ideas.</div>
                          <a href="it-services.html" className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                      <div className="serv-box s1 v5 bg-s1 md-mb-30">
                        <a href="it-services.html" className="overlay" />
                        <div className="icon-main"><span className="flaticon-tablet" /></div>
                        <div className="content-box">
                          <h5>Custom Software Development</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="serv-box s1 v5 bg-s2 md-mb-30">
                        <a href="web-development.html" className="overlay" />
                        <div className="icon-main"><span className="flaticon-report" /></div>
                        <div className="content-box">
                          <h5>Outsoursing Development</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="serv-box s1 v5 bg-s3">
                        <a href="mobile-development.html" className="overlay" />
                        <div className="icon-main"><span className="flaticon-ui" /></div>
                        <div className="content-box">
                          <h5>Software Product Development</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-clients">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Partners</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="partners">
                        <div className="owl-carousel owl-theme home-client-carousel">
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client1.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client2.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client3.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client4.png" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client5.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client6.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client1.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client2.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client3.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client4.png" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client5.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                          <div className="partners-slide">
                            <a href="#" className="client-logo">
                              <figure className="partners-slide-inner">
                                <img className="partners-slide-image" src="images/client-logos/client6.svg" alt="" />
                              </figure>                             
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-accordion-tabs">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Accordions &amp; Tabs</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="ot-accordions">
                        <div className="acc-item">
                          <span className="acc-toggle">How To Choose A Good QA Consultant? 
                            <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                          </span>
                          <div className="acc-content">
                            <p>Google has said for years that the most important single factor to them is high quality content. Now more than ever, they have the ability.</p>              
                          </div>
                        </div>
                        <div className="acc-item">
                          <span className="acc-toggle">How to Create a Chatbot to Fit Your Needs? 
                            <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                          </span>
                          <div className="acc-content">
                            <p>We help ambitious businesses like yours generate more profits by building awareness, driving web traffic, connecting with customers, and growing overall sales. Give us a call. </p>            
                          </div>
                        </div>
                        <div className="acc-item">
                          <span className="acc-toggle">What is off page SEO link building? 
                            <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                          </span>
                          <div className="acc-content">
                            <p>Google has said for years that the most important single factor to them is high quality content. Now more than ever, they have the ability.</p>
                          </div>
                        </div>
                        <div className="acc-item">
                          <span className="acc-toggle">How can I order web design for my website? 
                            <i className="down flaticon-download-arrow" /><i className="up flaticon-up-arrow" />
                          </span>
                          <div className="acc-content">
                            <p>Google has said for years that the most important single factor to them is high quality content. Now more than ever, they have the ability.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="ot-tabs">
                        <ul className="tabs-heading unstyle">
                          <li className="tab-link octf-btn current" data-tab="tab-1">analysis</li>
                          <li className="tab-link octf-btn" data-tab="tab-2">design</li>
                          <li className="tab-link octf-btn" data-tab="tab-3">testing</li>
                        </ul>
                        <div id="tab-1" className="tab-content current">
                          <p>While integrating the security with the DevOps approach has always been of high demand, this requires a completely different range of processes and tools. DevOps is already known to boast of an agile development methodology. Now when the inputs of the security experts I’ll be brought in a collaborative environment, the project will have an array of effective security safeguards.</p><p>In spite of disturbing the agile development process of the DevOps approach, the additional security inputs only enhance the safeguards and reliability of the project. So, in a way, DevSecOps will only extend the benefits of a DevOps approach further with security inputs.</p>
                        </div>
                        <div id="tab-2" className="tab-content">
                          <p>In spite of disturbing the agile development process of the DevOps approach, the additional security inputs only enhance the safeguards and reliability of the project. So, in a way, DevSecOps will only extend the benefits of a DevOps approach further with security inputs.</p><p>While integrating the security with the DevOps approach has always been of high demand, this requires a completely different range of processes and tools. DevOps is already known to boast of an agile development methodology. Now when the inputs of the security experts I’ll be brought in a collaborative environment, the project will have an array of effective security safeguards.</p>
                        </div>
                        <div id="tab-3" className="tab-content">
                          <p>DevOps is already known to boast of an agile development methodology. Now when the inputs of the security experts I’ll be brought in a collaborative environment, the project will have an array of effective security safeguards. While integrating the security with the DevOps approach has always been of high demand, this requires a completely different range of processes and tools.</p><p>In spite of disturbing the agile development process of the DevOps approach, the additional security inputs only enhance the safeguards and reliability of the project. So, in a way, DevSecOps will only extend the benefits of a DevOps approach further with security inputs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-counter">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Counter</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-4 mb-md-0">
                      <div className="misc-box text-white misc-box-bg1">
                        <div className="ot-counter">
                          <div>
                            <span className="num" data-to={15} data-time={2000}>0</span>
                            <span>+</span>
                          </div>                    
                        </div>
                        <h5>Countries Worldwide</h5>
                        <p>To succeed, every software solution must be deeply integrated into the existing tech environment..</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="misc-box misc-box-bg2">
                        <div className="ot-counter">
                          <div>
                            <span className="num" data-to={23} data-time={2000}>0</span>
                            <span>k</span>
                          </div>                    
                        </div>
                        <h5>Happy Customers</h5>
                        <p>To succeed, every software solution must be deeply integrated into the existing tech environment..</p>
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="row">
                    <div className="col-md-3 col-sm-6 col-12 mb-4 sb-sm-0 text-center">
                      <div className="ot-counter">
                        <div>
                          <span className="num" data-to={330} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6>Active Clients</h6>                             
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12 mb-4 sb-sm-0 text-center">
                      <div className="ot-counter">
                        <div>
                          <span className="num" data-to={850} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6>projects done</h6>                              
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12 text-center">
                      <div className="ot-counter">
                        <div>
                          <span className="num" data-to={25} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6>team advisors</h6>                              
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12 text-center">
                      <div className="ot-counter">
                        <div>
                          <span className="num" data-to={10} data-time={2000}>0</span>
                          <span>+</span>
                        </div>
                        <h6>Glorious Years</h6>                             
                      </div>
                    </div>
                  </div>
                  <div className="space-50" />
                  <div className="s-counter4 mt-0">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                        <div className="ot-counter text-white">
                          <div>
                            <span className="num" data-to={330} data-time={2000}>0</span>
                            <span>+</span>
                          </div>
                          <h6 className="text-white">active Clients</h6>                             
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-lg-0">
                        <div className="ot-counter text-white">
                          <div>
                            <span className="num" data-to={850} data-time={2000}>0</span>
                            <span>+</span>
                          </div>
                          <h6 className="text-white">projects done</h6>                              
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 text-center mb-4 mb-sm-0">
                        <div className="ot-counter text-white">
                          <div>
                            <span className="num" data-to={25} data-time={2000}>0</span>
                            <span>+</span>
                          </div>
                          <h6 className="text-white">team advisors</h6>                              
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 text-center">
                        <div className="ot-counter text-white">
                          <div>
                            <span className="num" data-to={10} data-time={2000}>0</span>
                            <span>+</span>
                          </div>
                          <h6 className="text-white">Glorious Years</h6>                             
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-testimonial">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Testimonial</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ot-testimonials">
                        <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                          <div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>                             
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                              </div>
                            </div>
                          </div>
                          <div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>                             
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                              </div>
                            </div>
                          </div>
                          <div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>                             
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                              </div>
                            </div>
                          </div>
                          <div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>                             
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elements-message">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Message Boxes</h2>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mb-30">
                      <div className="message-box">
                        <div className="icon-main info"><span className="flaticon-info" /></div>
                        <div className="content-box">
                          <h6>Information Box</h6>
                          <p>We help ambitious businesses like yours generate.</p>
                        </div>
                        <i className="flaticon-close" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-30">
                      <div className="message-box">
                        <div className="icon-main success"><span className="flaticon-correct" /></div>
                        <div className="content-box">
                          <h6>Confirmation Box</h6>
                          <p>We help ambitious businesses like yours generate.</p>
                        </div>
                        <i className="flaticon-close" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-30 mb-md-0">
                      <div className="message-box">
                        <div className="icon-main warning"><span className="flaticon-danger" /></div>
                        <div className="content-box">
                          <h6>Warning Box</h6>
                          <p>We help ambitious businesses like yours generate.</p>
                        </div>
                        <i className="flaticon-close" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="message-box">
                        <div className="icon-main danger"><span className="flaticon-close" /></div>
                        <div className="content-box">
                          <h6>Error Box</h6>
                          <p>We help ambitious businesses like yours generate.</p>
                        </div>
                        <i className="flaticon-close" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
           <Footer/>
          </div>{/* #page */}
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
        </>

)
}
};