import React, { useEffect, useState } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import config from '../config/config';
import { getTeamAction } from '../Action/action';
import DocumentMeta from 'react-document-meta';

const Ourteam = () => {
  const [teamlist, setGalleryList] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: '0px', behavior: 'smooth' });
    getGalleryAPI();
  }, [])

  const getGalleryAPI = async () => {
    let res = await getTeamAction();
    if (res.success) {
      setGalleryList(res.data);
    }
  }
  const meta = {
    title: 'Team of Espsofttech | Espsofttech Team | Esp softtech',
    description: 'Meet a dynamic team of observers and logical thinkers ready to develop the high-end solutions that are required to make your business succeed. Espsofttech team',
    canonical: 'https://espsofttech.com/Our_team',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'know our team, Espsofttech team, company team details, company team members, Espsoftech team members'
      }
    }
  }
  return (
    <>
      <DocumentMeta {...meta}>
        <div>
          <div id="page" className="site">
            <Header />
            <div id="content" className="site-content">
              <section className="team-top space-sm-top" style={{ padding: "0px" }}>
                <div className="container">
                <h1 className="team-top-title Teem">Meet The Team of Innovators</h1>
              </div>
                <div className="container-fluid text-center" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                  <div className='team-img'>
                    <img src="images/background/DSC09482-1.jpg" alt="Team" style={{ width: "100%", filter:'drop-shadow(#000 0px 1px 17px)' }} />
                  </div>
                </div>
              </section>
              <section className="team-our space-sm-bottom" style={{ backgroundColor: 'transparent' }}>
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="">
                        <h2 className="main-heading">Our Leadership Team</h2>
                      </div>
                      <div className="space-5" />
                      <p className="transform">The best team pages tell readers what their team brings to the table; the values they share, and what makes them different from others. They make their people accessible.</p>
                      <div className="space-20" />
                    </div>
                  </div>
                  <div className="row no-margin">
                    {teamlist.map(item => (
                      <div className='col-lg-3 col-md-6 mb-3 '>
                        <div class="team-block-one margin_bot_30 ">
                          <div class="inner-box">
                            <figure class="image-box">
                              <img src={`${config.imageUrl + item.images}`} alt="Rahul Bhadoriya" /></figure>
                            <div class="lower-content">
                              <div class="box">
                                <h5>{item.name}</h5>
                                <span class="designation">{item.designation}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </DocumentMeta>
    </>
  )
}

export default Ourteam