import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper";
export const insertContactsAction = (data) => {
  return postRequest("insertcontacts", data).then((res) => {
    return res.data;
  });
}

export const getGalleryAction = (data) => {
  return getRequest('getGalleryList', data).then(res => { return res.data })
}
export const getProjectAction = (data) => {
  return getRequest('getproject', data).then(res => { return res.data })
}
export const getTeamAction = (data) => {
  return getRequest('getteam', data).then(res => { return res.data })
}
export const getArticleListAction = (data) => {
  return getRequest('getArticles', data).then(res => { return res.data })
}
export const getArticleDetailsAction = (data) => {
  return postRequest('getArticleDetails', data).then(res => {return res.data})
}
export const getVideoLinkAction = (data) => {
  return getRequest('getvideos', data).then(res => { return res.data })
}