import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Fade from 'react-reveal/Fade';
import Carousel from "react-multi-carousel";

const responsiveTwo = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default class Portfolio2 extends Component {

    constructor(props) {
        super(props)

    }

    render() {

        return (
            <>
                <Header />
                <section>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                            <Fade delay={1500} down>
                                <div className='our-heading'>
                                    <h1>You Think IT , We Build IT</h1>
                                    <p>Crafted With Love. Built With Passion <span>Espsofttech</span></p>
                                </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        
                        {/* -------------------blockchain--------- */}
                        <div className='row pb-5'>
                        <Fade delay={1500} down>
                       <div className='col-sm-12'> 
                       <h3 className='our-head'>Blockchain Development</h3></div>
                       </Fade>
                            <div className='col-sm-6'>
                            <div class="overlay overlay-image-about5"></div>
                            <Fade delay={1000} left>
                                    <div className='our-work' style={{ padding: "35px 100px 30px 30px" }}>
                                        <h5>Explore the world of secure decentralized applications with our Blockchain development services.</h5>
                                        <p>Our blockchain development services are aimed at providing efficient and secure solutions for clients representing different domains. With profound knowledge and experience in developing and managing decentralized solutions, we build business processes highly secure, transparent, and globally accessible.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className='col-sm-6'>
                                {/* <img src="images/our1.webp" alt="" className='vert-move' /> */}
                               
                                <div className='our-img'>
                                    <Fade delay={1000} right>
                                    <img src="images/back1.jpg" className='back' alt="" />
                                    <img src="images/our1s.webp" className='front' alt="" />
                                        
                                    </Fade>
                                    
                                </div>
                            </div>
                            <div className='col-sm-12 mb-5'>
                            <Fade up>
                                <div className='subhead'>
                                    <h2>Cryptocurrency Exchange platform</h2>
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveTwo}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={4000}
                                    keyBoardControl={true}
                                    customTransition="all 1s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project1.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://btcpari.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://btcpari.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://btcpari.com/">BTC Pari</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/skysa.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://skysastock.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://skysastock.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://skysastock.com/">Skysa</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/arthbit.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://arthbit.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://arthbit.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://arthbit.com/">Arthbit</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project3.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://yusetoken.io/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://yusetoken.io/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="#">Yuse Token</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>;
                                </Fade>
                            </div>
                        </div>
    {/* --------------nft development---------------- */}
                        <div className='row'>
                        <Fade down>
                       <div className='col-sm-12'> 
                       <h3 className='our-head'>NFT Development</h3></div>
                       </Fade>
                            <div className='col-sm-6'>
                            <div class="overlay overlay-image-about5"></div>
                                <Fade left>
                                    <div className='our-work' style={{ padding: "35px 100px 30px 30px" }}>
                                        {/* <h3>NFT Development</h3> */}
                                        <h5>Non-fungible tokens are cannot be replaced by any other similar tokens, it is a unique tokens and Non-interchangeable. </h5>
                                        <p>Espsofttech provides end-to-end non-fungible token development services and solutions globally, The team of dedicated blockchain developers is specialized in creating the standard tokens with a set of protocols and also provides a complete set of solutions for creating secure and scalable dapps.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className='col-sm-6'>
                                <div className='our-img'>
                                    <Fade right>
                                        {/* <img src="images/nfts.webp" alt="" /> */}
                                        <img src="images/nft-back.jpg" className='back' alt="" />
                                        <img src="images/nft-front.jpg" className='front' alt="" />
                                    </Fade>
                                </div>
                            </div>
                            <div className='col-sm-12 mb-5 pb-5'>
                            <Fade up>
                                <div className='subhead'>
                                    <h2>NFT Marketplace platform</h2>
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveTwo}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={4000}
                                    keyBoardControl={true}
                                    customTransition="all 1s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project7.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://infinity8.io/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://infinity8.io/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://infinity8.io/">Infinity8</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project4.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://oddfellowsnft.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://oddfellowsnft.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://oddfellowsnft.com/">Odd Fellows NFTs</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project5.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://mynfthousemint.co.uk/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://mynfthousemint.co.uk/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://mynfthousemint.co.uk/">My NFT House Mint</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/project2.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://nftsland.io/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://nftsland.io/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://nftsland.io/">NFTs Land</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>;
                                </Fade>
                            </div>
                        </div>
    {/* ---------------------------defi development-------------------------- */}
                        <div className='row pb-5'>
                        <Fade down>
                       <div className='col-sm-12'> 
                       <h3 className='our-head'>Defi Development</h3></div>
                       </Fade>
                            <div className='col-sm-6'>
                                <div className='our-img'>
                                    <Fade left>
                                        {/* <img src="images/our1.webp" alt="" /> */}
                                        <img src="images/defi-back.jpg" className='back' alt="" />
                                        <img src="images/defi-front.png" className='front' alt="" />
                                    </Fade>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                            <div class="overlay overlay-image-about5"></div>
                                {/* <img src="images/our1.webp" alt="" className='vert-move' /> */}
                                <Fade right>
                                    <div className='our-work' style={{ padding: "35px 30px 30px 100px" }}>
                                        {/* <h3>Defi Development</h3> */}
                                        <h5>Thrive in the Emerging Decentralized Finance World with Our DeFi Development Solutions.</h5>
                                        <p>As a leading DeFi Development Company, Espsofttech holds the responsibility of both developing your DeFi application (dApp) and integrating your existing organization with decentralized finance (DeFi). With more than four years of proven industry experience in blockchain solutions and services, Espsofttech  promises its ability to convert your DeFi business dreams into reality.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className='col-sm-12'>
                            <Fade  up>
                                <div className='subhead mt-5' >
                                    <h2>Defi Development projects</h2>
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveTwo}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={4000}
                                    keyBoardControl={true}
                                    customTransition="all 1s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/nu.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://nugenesisou.com/en"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://nugenesisou.com/en">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://nugenesisou.com/en">NuGenesisou</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/snapx.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://app.snapxplatform.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://app.snapxplatform.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://app.snapxplatform.com/">App Snapx platform</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/metaseer.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://metaseer.io/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://metaseer.io/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://metaseer.io/">Metaseer</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/oction.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://oction.io/home"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://oction.io/home">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://oction.io/home">Oction</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>;
                                </Fade>
                            </div>
                        </div>
        {/* ------------------------------web development------------------------------- */}
                        <div className='row pb-5'>
                            <Fade down>
                       <div className='col-sm-12'> 
                       <h3 className='our-head'>Web Development</h3></div>
                       </Fade>
                            <div className='col-sm-6'>
                                <div className='our-img'>
                                    <Fade left>
                                        <img src="images/our1.webp" className='back' alt="" />
                                        <img src="images/web-front.jpg" className='front' alt="" />
                                    </Fade>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                            <div class="overlay overlay-image-about5"></div>
                                {/* <img src="images/our1.webp" alt="" className='vert-move' /> */}
                                <Fade right>
                                    <div className='our-work' style={{ padding: "35px 30px 30px 100px" }}>
                                        {/* <h3>Web Development</h3> */}
                                        <h5>Helping Businesses build secure and scalable Web Applications, Portals and Solutions.</h5>
                                        <p>We provide a wide range of services. At Espsofttech, we dig deep and understand our client’s objectives and unique business challenges to provide the best-suited web app solution.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className='col-sm-12'>
                            <Fade  up>
                                <div className='subhead mt-5' >
                                    <h2>Web Development projects</h2>
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveTwo}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={4000}
                                    keyBoardControl={true}
                                    customTransition="all 1s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/localcoin.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://coinexgroup.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://coinexgroup.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://coinexgroup.com/">Local coin</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/monstar.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://monstarznft.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://monstarznft.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://monstarznft.com/">Monstarz Nft</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/onebig.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://www.onebigwin.co.uk/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://www.onebigwin.co.uk/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://www.onebigwin.co.uk/">One big win</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/oction.png" class="" alt="" />
                                                    {/* <span class="overlay"></span> */}
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://oction.io/home"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://oction.io/home">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://oction.io/home">Oction</a>
                                                        {/* <a href="#">Ideas</a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>;
                                </Fade>
                            </div>
                        </div>
            {/* ------------------------------mobile application--------------- */}
            <div className='row pb-5'>
            <Fade down>
                       <div className='col-sm-12'> 
                       <h3 className='our-head'>Mobile Development</h3></div>
                       </Fade>
                            <div className='col-sm-6'>
                                <div className='our-img'>
                                    <Fade left>
                                        <img src="images/our1.webp" alt="" />
                                    </Fade>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                        <div class="overlay overlay-image-about5"></div>
                                {/* <img src="images/our1.webp" alt="" className='vert-move' /> */}
                                <Fade right>
                                    <div className='our-work' style={{ padding: "35px 30px 30px 100px" }}>
                                        {/* <h3>Mobile Development</h3> */}
                                        <h5>Espsofttech mobile development team has exceptional experience in creating native, hybrid, and cross-platform mobile apps.</h5>
                                        <p>Espsofttech is an experienced mobile app development company with a proven track record of delivering effective project management and flexible delivery.</p>
                                    </div>
                                </Fade>
                            </div>
                            {/* <div className='col-sm-12'>
                            <Fade  down>
                                <div className='subhead mt-5' >
                                    <h2>Web Development projects</h2>
                                </div>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveTwo}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    autoPlaySpeed={4000}
                                    keyBoardControl={true}
                                    customTransition="all 1s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/localcoin.png" class="" alt="" />
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://coinexgroup.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://coinexgroup.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://coinexgroup.com/">Local coin</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/monstar.png" class="" alt="" />
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://monstarznft.com/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://monstarznft.com/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://monstarznft.com/">Monstarz Nft</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/onebig.png" class="" alt="" />
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://www.onebigwin.co.uk/"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://www.onebigwin.co.uk/">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://www.onebigwin.co.uk/">One big win</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="project-item projects-style-2">
                                        <div class="projects-box">
                                            <div class="projects-thumbnail">
                                                <a href="#">
                                                    <img src="images/oction.png" class="" alt="" />
                                                </a>
                                            </div>
                                            <div class="portfolio-info ">
                                                <div class="portfolio-info-inner">
                                                    <a class="btn-link" href="https://oction.io/home"><i class="flaticon-right-arrow-1"></i></a>
                                                    <h5><a href="https://oction.io/home">Go to URL</a></h5>
                                                    <p class="portfolio-cates">
                                                        <a href="https://oction.io/home">Oction</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>;
                                </Fade>
                            </div> */}
                        </div>
                    </div>
                </section>
             
                {/* <Footer/> */}
            </>
        )
    }
}
// export default FadeExample;