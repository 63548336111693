

import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import DocumentMeta from 'react-document-meta';
export default class about extends Component {

  constructor(props) {
    super(props)

  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }
  render() {
    const meta = {
      title: 'About Espsofttech| A Global Leader in Blockchain Development | Espsofttech',
      description: 'Espsofttech is a world-class blockchain service provider, who has expertise in cryptocurrency development, healthcare, Automation, & mobility domains. We are designers, engineers, developers, analysts, programmers, testers, blockchain ',
      canonical: 'https://espsofttech.com/About',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'About Espsofttech, a blockchain development company, who we are, what we do'
        }
      }
    }
    return (
      <>
        <DocumentMeta {...meta}>
          <div id="page" className="site">
            <Header />
            <div id="content" className="site-content about-us" style={{ overflowX: "hidden" }}>
              {/* <div className="page-header flex-middle">
             
            </div> */}
              <section className='about-background all-bg'>
                <div className='container-fluid p-0'>
                  <div class="elementor-background-overlay"></div>
                  <div className='portfolio-head'>
                    <h1>About Us</h1>
                    <p>Espsofttech will help you improve your business operations with an optimal service or assist you in launching a product that would win customers fast.</p>
                  </div>
                </div>
              </section>
              <section className="about-offer pt-5 mt-3 pb-0">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center mb-30 mb-lg-0">
                      <div className="ot-heading">
                        <h2 className="main-heading transform text-center">WE TRANSFORM YOUR VISION INTO REALITY</h2>
                        {/* <span>A PIONEER IN GLOBAL IT SOLUTIONS</span> */}
                      </div>
                      <div className="space-5" />
                      <p className="transform">Welcome to ESP Softtech Pvt. Ltd. founded in 2013 by the team of young technology lovers with strong aim of providing most trusted service to the customer. When we first started out, our passion for Blockchain technology drove us to do intense research in this field and gave us the impetus to turn hard work and inspiration into a blockchain based services.</p>
                      <p className="transform">ESP Softtech Pvt. Ltd. is a fastest growing organization in the field of Blockchain Services situated at middle of India.</p>
                      <p className="transform">We now serve customers all over the world and are thrilled to be a part of the fair trade wing of every industry. We provide high end solutions for any type of business that you own or are planning to initiate. We’re experts in merging blockchain technology in the venture.</p>
                      <p className="transform">We hope you enjoy our services as much as we enjoy offering them to you.</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="" style={{ backgroundColor: 'transparent' }}>
                <div className="overlay overlay-image-about5" />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 ">
                      {/* <div className="pabout-left">
                      <img src="images/image1-about.png" alt="Our-service" />
                    </div> */}
                      <div className='about-sec1'>
                        {/* <div className='row'>
                        <div className='col-sm-6 p-0'>
                          <div className='owl-img'>
                            <img src='images/about1.jpeg' className='imgone'/>
                          </div>
                        </div>
                        <div className='col-sm-6 '>
                        <div class="circle" style={{top:"50%"}}>
                          <div class="color-circle"><h3>12+</h3><p>Years experience</p></div></div>
                          <div className='owl-img'>
                            <img src='images/about2.jpeg' className='imgtwo'/>
                          </div>
                        </div>
                      </div> */}
                        <div className='upper_circle'>
                          <div className='inner_circle'>
                            <div className='cirle_img'>
                              <img src='images/about2.jpeg' className='imgtwo' />
                              <div className='exp_bx'>
                                <div className='exp_bx_in'>
                                  <h2>12+</h2>
                                  <p> Experience</p>
                                </div>
                              </div>
                              <div className='exp_bx2'>
                                <div className='exp_bx_in'>
                                  <h2>850+</h2>
                                  <p> Projects Done</p>
                                </div>
                              </div>
                              <div class="arc arc_end"></div>
                              <div class="arc2 arc_end2"></div>
                              <div class="arc3 arc_end3"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="pabout-right">
                        <div className="ot-heading ">

                          <h2 className="main-heading experience" style={{ textAlign: "left" }}>What We Actually Do</h2>
                          <span> Experience. Execution. Excellence.</span>
                        </div>
                        <p className="transform">ESP Softtech  believe in delivering idea’s not project. Our team is enthusiastic about conveying results to customers. We help businesses elevate their value through custom software development, product design, QA and consultancy services</p>
                        <div className="tech-wrap pt-3">
                          <a className="tech-box border" href={`${config.baseUrl}Blockchain_service`}>
                            <div className="icon-main"><span className="flaticon-report-1" /></div>
                          </a>
                          <a className="tech-box border" href={`${config.baseUrl}Nft_development`}>
                            <div className="icon-main"><span className="flaticon-browser" /></div>
                          </a>
                          <a className="tech-box border" href={`${config.baseUrl}Defi_development`}>
                            <div className="icon-main"><span className="flaticon-best" /></div>
                          </a>
                          <a className="tech-box border" href={`${config.baseUrl}Web_development`}>
                            <div className="icon-main"><span className="flaticon-code" /></div>
                          </a>
                          <a className="tech-box border" href={`${config.baseUrl}Mobile_development`}>
                            <div className="icon-main"><span className="flaticon-monitor" /></div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="about-counter mt-5" style={{ background: 'transparent' }}>
                <div className="container pt-md-4">

                  <div className="row">
                    <div className="col-lg-5 p-0" style={{ position: "unset" }}>
                      <div className="technology-v3-left">
                        <div className="ot-heading">
                          <h2 className="main-heading text-white"> Technology index</h2>
                          <span>Improve and Innovate with the Tech Trends</span>
                        </div>
                        <div className="space-5" />
                        <p className="transform">We serve customers all over the world and are thrilled to be a part of the fair trade wing of every industry. We provide high end solutions for any type of business that you own or are planning to initiate. We’re experts in merging blockchain technology in the venture.</p>
                        <div className="space-10" />
                        <div className="et-progress">
                          <div className="ot-progress">
                            <div className="overflow">
                              <span className="pname f-left text-white">Blockchain Development</span>
                              <span className="ppercent f-right text-white">90%</span>
                            </div>
                            <div className="iprogress">
                              <div className="progress-bar" data-percent="90%" />
                            </div>
                          </div>
                          <div className="ot-progress">
                            <div className="overflow">
                              <span className="pname f-left text-white">Mobile App Development</span>
                              <span className="ppercent f-right text-white">80%</span>
                            </div>
                            <div className="iprogress">
                              <div className="progress-bar" data-percent="80%" />
                            </div>
                          </div>
                          <div className="ot-progress">
                            <div className="overflow">
                              <span className="pname f-left text-white">Web development</span>
                              <span className="ppercent f-right text-white">85%</span>
                            </div>
                            <div className="iprogress">
                              <div className="progress-bar" data-percent="85%" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 p-0">
                      {/* <img src="images/imporve.jpg" alt="Improvement" /> */}
                      <img src='images/wave _img.png' style={{ maxWidth: "initial" }} />
                    </div>
                  </div>

                </div>

              </section>
              {/* <section className="team-about sm-space">
              <div className="container">
                <div className="steam-info">
                  <div className="row">
                    <div className="col-lg-6">
                   
                      <div className="team-info-left">
                      <h1 style={{marginLeft:"30px"}}>Our Director</h1>
                        <div className="team-img">
                          <img src="images/team/rahul-sir.jpeg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="team-detail">
                        <h2>Rahul Bhadoriya</h2>
                        <span className="location">CEO of Company</span>
                        <ul className="bold member-info">
                          <li><span className="text-white">Department:</span> Web Development</li>
                          <li><span className="text-white">Experience:</span> 15 Years</li>
                          <li><span className="text-white">Email:</span> rahul@mail.com</li>
                          <li><span className="text-white">Phone:</span> +1-800-456-478-23</li>
                        </ul>
                        <div className="otf-social-share clearfix shape-circle">
                          <a className="share-facebook" href="facebook.com" target="_blank"><i className="fab fa-facebook-f" /></a>
                          <a className="share-twitter" href="twitter.com" target="_blank"><i className="fab fa-twitter" /></a>
                          <a className="share-pinterest" href="pinterest.com" target="_blank"><i className="fab fa-pinterest-p" /></a>
                          <a className="share-linkedin" href="linkedin.com" target="_blank"><i className="fab fa-linkedin-in" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
              {/* <section className="team-slills">
              <div className="container">
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <h3 className="text-white">Biography</h3>
                    <p className="transform">A vast majority of the app marketers mainly concentrate on the post-launch app marketing techniques and measures while completely missing on the pre-launch campaign. This prevents the app to create buzz and hype just around the time when the app is launched. As and when you launch the app, already a considerable number of people should expectantly look forward to your app and this requires long-drawn marketing efforts leading up to the app launch event. To create pre-launch buzz and hype about the app a mobile app development company has an array of marketing options like social media campaign, search engine ads, video ads, email campaigns, etc. Apart from online options, you can also reach out to the wider audience with traditional marketing options like outdoor ads, print ads, media ads, and promotional events.</p>
                  </div>
                </div>
                <div className="space-25" />
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6 text-center text-md-left">
                    <div className="team-profile">
                      <h6>Onum Company</h6>
                      <p className="text-white">Web Designer, Illustrator</p>
                    </div>
                    <div className="team-profile">
                      <h6>Media Group</h6>
                      <p className="text-white">Photographer, UI Designer</p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 text-center text-md-left">
                    <div className="team-profile">
                      <h6>Onum Company</h6>
                      <p className="text-white">Web Designer, Illustrator</p>
                    </div>
                    <div className="team-profile">
                      <h6>Nimkus 2000</h6>
                      <p className="text-white">PHP Developer, Photographer</p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div className="circle-progress flex-middle" data-color="#43BAFF" data-height={10} data-size={135}>
                      <div className="inner-bar" data-percent={50}>
                        <span className="text-white"><span className="percent">0</span>%</span>
                      </div>
                      <h4 className="text-white">Mobile <br />Development</h4>        
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6 mb-4">
                    <div className="circle-progress flex-middle" data-color="#43BAFF" data-height={10} data-size={135}>
                      <div className="inner-bar" data-percent={70}>
                        <span className="text-white"><span className="percent">0</span>%</span>
                      </div>
                      <h4 className="text-white">Wearable <br />Development</h4>        
                    </div>
                  </div>
                </div>
                <div className="space-25" />
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="text-white">Hard Skills</h3>
                    <p className="transform">Just as a retail business in real life is remembered not just for its product offerings but also because of its services, support, and customer-friendliness, an app that offers a <span className="transform bold">helpful customer support system</span> for its valued users enjoy more traction and engagement than other apps. Great brands all over the globe enjoy appreciation and popularity because of their customer-friendly support and services.</p>
                  </div>
                </div>
              </div>
            </section> */}
              {/* <section className="team-contact">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <form action="teamcontact.php" method="post" className="wpcf7-form init">
                      <div className="member-form comment-respond">
                        <h2 className="text-white">Contact Me</h2>
                        <p className="font14 transform">Your email address will not be published. Required fields are marked *</p>
                        <div className="row">
                          <p className="col-md-6">
                            <input type="text" name="name" defaultValue size={40} aria-required="true" placeholder="Your Name *" required />
                          </p><p className="col-md-6">
                            <input type="email" name="email" defaultValue size={40} aria-required="true" placeholder="Your Email *" required />
                          </p>
                        </div>
                        <p>
                          <input type="text" name="site" defaultValue size={40} placeholder="Website" />
                        </p>
                        <p>
                          <textarea name="message" cols={40} rows={10} placeholder="Message..." required defaultValue={""} />
                        </p>
                        <p>
                          <button type="submit" className="octf-btn octf-btn-primary">Send Message</button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section> */}
            </div>
            <Footer />
          </div>
        </DocumentMeta>
      </>

    )
  }
}