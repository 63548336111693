import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
export default class Binance_smartchain extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <div>
          <div id="page" className="site">
            <div id="particles-js" />
            <Header />
            <div id="content" className="site-content">
              {/*  <div class="page-header flex-middle banner-content">
                  <div class="container">
                      <div class="inner flex-middle">
                          <h2 class="page-title">Blockchain Services</h2>
                          <ul id="breadcrumbs" class="breadcrumbs none-style">
                              <li><a href="index.html">Home</a></li>
                              <li class="active">Blockchain Services</li>
                          </ul>    
                      </div>
                  </div>
              </div> */}
              <section className="service-page binance_smart_chain">
                <div className="container">
                  <div className="row">
                    {/* <div class="col-sm-4 wow fadeInDown" ata-wow-duration="1000ms" data-wow-delay="0ms">
                             <a class="globalImgBox2" href="../blockchain/blockchain-and-allied-tech">
                                <figure class="">
                                <img src="https://www.mobiloitte.com/images/blockchain-banner/blockchain-thumb1.png" alt="">
                                </figure>
                                <div class="globalImgInfoBox">
                                   <div class="w-100 ">
                                         <h2>Blockchain &amp; Allied Tech</h2>
                                         <p>Transforming business with Blockchain Technology</p>
                                         <button type="button" class="btn btn-common mt-3">Read More<i class="fa fa-angle-double-right"></i></button>
                                   </div>
                                </div>
                             </a>
                       </div> */}
                    <div className="col-md-12 col-sm-12 mb-4 mb-sm-0">
                      <div className="ot-heading mb-0">
                        {/*  <span> our service</span> */}
                        <h2 className="main-heading text-white">Binance Smart Chain Development</h2>
                        <p className="text-white">Our Binance Smart Chain Development services comprises the development of DeFi Protocols, NFT Marketplaces, Smart Contracts and more on Binance Smart Chain, the chain that runs parallel to the Binance Chain.The major benefits of choosing Binance Smart Chain for your DeFi Development involves functionalities like Staking, farming, swapping, lending, borrowing of BEP 20 tokens are carried out securely with ease on BSC.</p>
                      </div>
                    </div>
                    <div className="col-md-0 col-sm-0 text-sm-right align-self-end">
                      {/* <div class="ot-button">
                                  <a href="index-2.html" class="octf-btn octf-btn-primary">all sevices</a>
                              </div>*/}
                      <div className="space-10" />
                    </div>
                  </div>
                  <div className="space-55" />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg  text-center  pb-60">
                        <div className="icon-main"><span className="flaticon-code" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Binance Smart Chain Smart Contract Development</h5>
                          {/* <p class="text-white">We carry more than just good coding skills. Our experience makes us stand out from other web development.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg  text-center ">
                        <div className="icon-main"><span className="flaticon-report-1" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Binance Smart Chain Token(BEP20) Development</h5>
                          {/* <p class="text-white">Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg  text-center ">
                        <div className="icon-main"><span className="flaticon-monitor" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Binance Smart Chain NFT Marketplace Development</h5>
                          {/* <p class="text-white">Build the product you need on time with an experienced team that uses a clear and effective design process.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg  text-center ">
                        <div className="icon-main"><span className="flaticon-browser" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Binance Smart Chain DeFi Protocol Development</h5>
                          {/* <p class="text-white">Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg  text-center ">
                        <div className="icon-main"><span className="flaticon-report-1" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Binance Smart Chain Non-Fungible Token Development and more.</h5>
                          {/* <p class="text-white">Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <section class="service-pricing" style="background-color: transparent;">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-heading">
                                  <span>// choose your plan</span>
                                  <h2 class="main-heading text-white">Flexible Pricing Plans</h2>
                              </div>
                              <div class="space-5"></div>
                              <p class="text-white">We help businesses elevate their value through custom software development,<br>product design, QA and consultancy services.</p>
                              <div class="space-20"></div>
                          </div>
                      </div>
                      <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-tablet"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Basic Plan</h4>
                                      <h2><sup>$</sup> 129.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                              <li class="active">Web Counsulting</li>
                                              <li class="active">24/7 System Monitoring</li>
                                              <li class="active">Machine and Deep Learning</li>
                                              <li>Data Quality Management</li>
                                              <li>Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-report"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Economy Plan</h4>
                                      <h2><sup>$</sup> 159.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                              <li class="active">Web Counsulting</li>
                                              <li class="active">24/7 System Monitoring</li>
                                              <li class="active">Machine and Deep Learning</li>
                                              <li class="active">Data Quality Management</li>
                                              <li>Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4 col-md-6">
                              <div class="ot-pricing-table">
                              <div class="icon-main"><span class="flaticon-ui"></span></div>
                                  <div class="inner-table">
                                      <h4 class="title-table text-white">Premium Plan</h4>
                                      <h2><sup>$</sup> 189.99</h2>
                                      <span>Monthly Package</span>
                                      <div class="details ">
                                          <ul>
                                          <li class="active">Web Counsulting</li>
                                          <li class="active">24/7 System Monitoring</li>
                                          <li class="active">Machine and Deep Learning</li>
                                          <li class="active">Data Quality Management</li>
                                          <li class="active">Security Management</li>
                                          </ul>
                                      </div>
                                      <a href="#" class="octf-btn">Choose Plane</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
  
              <section class="section-consultation">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12 text-center">
                              <p>// Drop us a line! We are here to answer your questions 24/7</p>
                              <h2>NEED A CONSULTATION?</h2>
                              <div class="ot-button">
                                  <a href="contact.html" class="octf-btn octf-btn-primary">Contact Us</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
  
              <section class="service-clients bg-map-dots"> 
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-heading text-center">
                                  <span>// our clients</span>
                                  <h2 class="main-heading">We are Trusted <br>15+ Countries Worldwide</h2>
                              </div>
                          </div>
                      </div>
                      <div class="space-35"></div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="ot-testimonials">
                                  <div class="owl-carousel owl-theme testimonial-inner ot-testimonials-slider">
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi2.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>SoftTech,</h6>
                                                          <span>Manager of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi1.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>Moonkle LTD,</h6>
                                                          <span>Client of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi2.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>SoftTech,</h6>
                                                          <span>Manager of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                      <div class="testi-item">
                                          <div class="layer1"></div>
                                          <div class="layer2">
                                              <div class="t-head flex-middle">
                                                  <img src="images/testi1.png" alt="Emilia Clarke" class="lazyloaded" data-ll-status="loaded">
                                                      <div class="tinfo">
                                                          <h6>Moonkle LTD,</h6>
                                                          <span>Client of Company</span>                             
                                                      </div>
                                              </div>
                                              <div class="ttext">
                                                  "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."                           
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section> */}
              <section className="clients-v5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="ot-heading">
                        <span className="text-primary-light"> our clients</span>
                        <h2 className="main-heading text-white">We are Trusted <br />15+ Countries Worldwide</h2>
                      </div>
                      <div className="space-35" />
                    </div>
                    <div className="col-lg-12">
                      <div className="ot-testimonials testimonials-style-2">
                        <div className="owl-carousel owl-theme testimonial-inner ot-testimonials-slider owl-loaded owl-drag">
                          <div className="owl-stage-outer"><div className="owl-stage" style={{ transform: 'translate3d(-1200px, 0px, 0px)', transition: 'all 0s ease 0s', width: '4800px' }}><div className="owl-item cloned"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item cloned"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item active"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item active"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item cloned"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi2.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>SoftTech,</h6>
                                  <span>Manager of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div></div><div className="owl-item cloned"><div className="testi-item">
                            <div className="layer1" />
                            <div className="layer2">
                              <div className="t-head flex-middle">
                                <img src="images/testi1.png" alt="Emilia Clarke" className="lazyloaded" data-ll-status="loaded" />
                                <div className="tinfo">
                                  <h6>Moonkle LTD,</h6>
                                  <span>Client of Company</span>
                                </div>
                              </div>
                              <div className="ttext">
                                "Very well thought out and articulate communication. Clear milestones, deadlines and fast work. Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always solving problems with great original ideas!."
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                          </div>
                          <div className="owl-nav">
                            <button type="button" role="presentation" className="owl-prev">
                              <i className="flaticon-back" />
                            </button>
                            <button type="button" role="presentation" className="owl-next">
                              <i className="flaticon-right-arrow-1" />
                            </button>
                          </div>
                          <div className="owl-dots disabled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <footer id="site-footer" className="site-footer footer-v2">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <img src="images/esp-logo3.png" alt="" width="250px" />
                  </div>
                </div>
                <div className="space-60" />
                <div className="row justify-content-center">
                  <div className="col-md-4 col-sm-6">
                    <div className="contact-info box-style2 ft-contact-info">
                      <div className="box-icon"><i className="flaticon-world-globe" /></div>
                      <p>101, Platinum Plaza, PU4, Vijay Nagar, Indore (452010)</p>
                      <h6>Our Address</h6>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="contact-info box-style2 ft-contact-info">
                      <div className="box-icon"><i className="flaticon-envelope" /></div>
                      <p>info@espsofttech.in</p>
                      <h6>Our Mailbox</h6>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="contact-info box-style2 ft-contact-info">
                      <div className="box-icon"><i className="flaticon-phone-1" /></div>
                      <p> +91-9039782524</p>
                      <h6>Our Phone</h6>
                    </div>
                  </div>
                </div>
                <div className="space-65" />
                <div className="row">
                  <div className="col-md-12 text-center">
                    <div className="footer-menu">
                      <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Services</a></li>
                        <li><a href="#">Portfolio</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contacts</a></li>
                      </ul>
                    </div>
                    <div className="space-20" />
                    <p className="copyright-text v2">Copyright @ 2021 <a href="#" style={{ color: '#43baff' }}>ESP Softtech Pvt Ltd</a>. All Rights Reserved.</p>
                    <div className="space-26" />
                    <div className="ft-list-icon">
                      <a className="twitter" href="https://twitter.com/Espsofttechpltd"><i className="fab fa-twitter" /></a>
                      <a className="facebook" href="https://www.facebook.com/espsofttechpvtltd"><i className="fab fa-facebook-f" /></a>
                      <a className="linkedin" href="#"><i className="fab fa-linkedin-in" /></a>
                      <a className="instagram" href="https://www.instagram.com/espsofttech_pvtltd/"><i className="fab fa-instagram" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
      </>
    );
  }
};