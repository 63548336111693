import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';


export default class about extends Component {

  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <div id="page" className="site">
          <div id="particles-js" />
          <Header />
          <div id="content" className="site-content">
          </div>
          <div className="">
            <div className="container">
              <div className="blog-grid pgrid">
                <div className="row mb-5 mt-3">
                    <div className="ot-heading mb-0">
                      <h2 className="main-heading text-white">Blog</h2>
                    </div>
                  </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat">
                            <span className="posted-in">
                              <a href="#" rel="category tag">Development</a>
                            </span>
                          </div>
                          <a href="post.html"><img src="images/blog1-601x520.jpg" alt="" /></a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">November 21, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">How Web Apps Can  Benefit Online Stores?</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat">
                            <span className="posted-in">
                              <a href="#" rel="category tag">Development</a>
                            </span>
                          </div>
                          <a href="post.html">
                            <img src="images/blog/grid2.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">November 21, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">0 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Plan Your Project with  Your Software</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid3.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Does Magento Shared Hosting Suit You? </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a className="btn-play" href="https://vimeo.com/87959439"><i className="flaticon-play" /></a>
                          <img src="images/blog/grid4.jpg" alt="" />
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Which Database is Ideal for Your Business Need? </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <div className="gallery-post img-slider owl-carousel owl-theme">
                            <div className="item-image">
                              <img src="images/blog/grid5.jpg" alt="" />
                            </div>
                            <div className="item-image">
                              <img src="images/blog/grid11.jpg" alt="" />
                            </div>
                            <div className="item-image">
                              <img src="images/blog/grid12.jpg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Plan Your Project with Your Software </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid6.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">5 Effective Tips to Build An Engaging App</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid7.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">How Web Design Can Enhance Experience?</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid8.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">8 Interesting Ideas For Travel App</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid9.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Building Data Analytics Software </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid10.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">Tackling the Changes of Retail Industry </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid11.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">How to Make Website WCAG Compliant? </a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <article className="post-box blog-item">
                      <div className="post-inner">
                        <div className="entry-media">
                          <div className="post-cat"><span className="posted-in"><a href="#" rel="category tag">Development</a></span></div>
                          <a href="post.html">
                            <img src="images/blog/grid12.jpg" alt="" />
                          </a>
                        </div>
                        <div className="inner-post">
                          <div className="entry-header">
                            <div className="entry-meta">
                              <span className="posted-on">_ <a href="#">September 24, 2019</a></span>
                              <span className="byline">_ <a className="url fn n" href="#">Tom Black</a></span>
                              <span className="comment-num">_ <a href="#">3 Comments</a></span>
                            </div>{/* .entry-meta */}
                            <h3 className="entry-title"><a href="post.html">You have a Great Business Idea?</a></h3>
                          </div>{/* .entry-header */}
                          <div className="btn-readmore">
                            <a href="post.html"><i className="flaticon-right-arrow-1" />LEARN MORE</a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div className="ot-button text-center">
                <a href="#" className="octf-btn octf-btn-primary">Load More</a>
              </div>
            </div>
          </div>
          <Footer />
        </div>{/* #page */}
        );
      </>

    )
  }
}