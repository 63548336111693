import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import "react-multi-carousel/lib/styles.css";
import DocumentMeta from 'react-document-meta';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default class Blockchain_service extends Component {

  constructor(props) {
    super(props)

  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
   }

  render() {
    const meta = {
      title: ' Blockchain Development Company | Blockchain App Development & Consulting Services in India | Espsofttech',
      description: 'Blockchain Development Company. With our proven expertise in Blockchain Technologies, We deliver Blockchain development Services that are Highly Secure, Transparent, and Yet Scalable. Espsofttech is a prominent Blockchain App Development company in India',
      canonical: 'https://espsofttech.com/Blockchain_service',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'blockchain development company, blockchain technologies, blockchain consulting, smart contract development, blockchain application development, blockchain solutions, fintech application development, blockchain developers'
        }
      }
    }
    return (
      <>
       <DocumentMeta {...meta}>
        <div>
          <div id="page" className="site">
            <div id="particles-js" />
            <Header />
            <div id="content" className="site-content">
              <section className='background all-bg'>
                <div className='container-fluid p-0'>
                  <div class="elementor-background-overlay"></div>
                  <div className='portfolio-head'>
                    <h1>Blockchain Development</h1>
                    <p>Launch, Run, Secure, Scale, and Integrate your business with adaptive blockchain technology and become a part of a progressive business ecosystem.</p>
                  </div>
                </div>
              </section>
              <section className="service-page" id='blockchain_technology'>
                <div className="container">
                  <div className="space-55" />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><span className="flaticon-code" /></div>
                        <div className="content-box">
                          <h5 className="transform">Smart Contracts Audit</h5>
                          <p className="transform">At Espsofttech, our blockchain experts harness their experience and expertise to audit smart contracts. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                        <div className="icon-main"><img src="images/ethereum.png" width="40px" /></div>
                        <div className="content-box">
                          <h5 className="transform">Ethereum</h5>
                          <p className="transform">Ethereum is an open source, distributed software platform that is based on blockchain technology.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                        <div className="icon-main"><span className="flaticon-monitor" /></div>
                        <div className="content-box">
                          <h5 className="transform">Blockchain &amp; Allied Tech</h5>
                          <p className="transform">Espsofttech expertises in developing customized Blockchain Apps.  </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg4 text-center ">
                        <div className="icon-main"><span className="flaticon-browser" /></div>
                        <div className="content-box">
                          <h5 className="transform">Blockchain Development</h5>
                          <p className="transform">Blockchain development services help create decentralized blockchain networks that provide increased traceability and security of data and transactions.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                        <div className="icon-main"><span className="flaticon-report-1" /></div>
                        <div className="content-box">
                          <h5 className="transform">Decentralized Finance (DeFi) Development</h5>
                          <p className="transform">Decentralized finance (DeFi) is an emerging financial technology based on secure distributed ledgers similar to those used by cryptocurrencies</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bb6 text-center">
                        <div className="icon-main"><img src="images/hyperledger.png" width="40px" /></div>
                        <div className="content-box">
                          <h5 className="transform">Hyperledger Development </h5>
                          <p className="transform">Hyperledger is an ecosystem that supports not only blockchain protocols but also distributed ledger and smart contracts
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg7 text-center ">
                        <div className="icon-main"><img src='images/icons/bitcoin.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Cryptocurrency Coin Integration</h5>
                          <p className="transform">CryptoCurrency is now on a trend. This Currency exchange medium uses Cryptographic methods which makes the transaction secure and enable us to create additional and New Cryptocurrencies
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bb8 text-center ">
                        <div className="icon-main"><span className="flaticon-browser" /></div>
                        <div className="content-box">
                          <h5 className="transform">IEO Development</h5>
                          <p className="transform">IEO or Initial Exchange Offering is a new method of using blockchain that can be used by businesses to enter the market of Cryptocurrency and Crowdfunding.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg9 text-center ">
                        <div className="icon-main">
                          <img src="images/crypto-wallet.png" width="40px" />
                        </div>
                        <div className="content-box">
                          <h5 className="transform">Crypto Exchanges</h5>
                          <p className="transform">A cryptocurrency exchange, or a digital currency exchange (DCE), is a business that allows customers to trade cryptocurrencies.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg10 text-center ">
                        <div className="icon-main"><img src='images/icons/CryptoWallet.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Crypto Wallets</h5>
                          <p className="transform">A hardware crypto wallet is a physical device, which stores your private keys offline, and is therefore not accessible via the internet.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/ICO.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">ICO Launch </h5>
                          <p className="transform">An initial coin offering (ICO) is the cryptocurrency industry's equivalent to an initial public offering (IPO).
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                        <div className="icon-main"><span className="flaticon-best" /></div>
                        <div className="content-box">
                          <h5 className="transform">STO Development</h5>
                          <p className="transform">We help you to raise funds for your venture by legally tokenizing your real world assets through security token offering with sec compliance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                        <div className="icon-main"><img src='images/icons/landing.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Crypto Lending Platform </h5>
                          <p className="transform">The crypto lending platforms are the third-party platforms that connect borrowers and lenders and take care of these transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg4 text-center ">
                        <div className="icon-main"><img src='images/icons/cryptoapp.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Cryptocurrency Application</h5>
                          <p className="transform">Espsofttech is a master in the field of Cryptocurrency App Development. We have hands on experience in creating quality mobile and web applications for Cryptocurrencies like Bitcoin, Ripple, Ether, Litecoin and Monero.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                        <div className="icon-main"><img src='images/icons/ICO.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">ICO Development</h5>
                          <p className="transform">ICO development is a process in which companies raise capital by creating coins/tokens, and selling them in exchange for investment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

            </div>
            {/* Blockchain Help Section */}
            <div className="section-block-grey" id='blockchain_help'>
              <div className="container">
                <div className="center-holder section-heading">
                  <h3>How Will Blockchain Help?</h3>
                  {/* <div class="section-heading-line"></div> */}
                </div>
                <div className="row mt-50">
                  <div className="col-xs-12 col-md-4 col-sm-4">
                    <div className="feature-box-long h-325">
                      <div className="icon-img">
                        <img src="images/creation.png" alt="creation" />
                      </div>
                      <h3>Creation of Value</h3>
                      <p>In a value-driven global economy, companies must strive for improvements in new processes and opportunities. Blockchain will help in creating optimized business models. </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-4 col-sm-4">
                    <div className="feature-box-long h-325">
                      <div className="icon-img">
                        <img src="images/efficient.png" alt="efficient" />
                      </div>
                      <h3>Efficient Ecosystem</h3>
                      <p>Blockchain Technology will streamline transactions and other business processes with suppliers, partners, contractors, and most importantly with the customers.</p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-4 col-sm-4">
                    <div className="feature-box-long h-325">
                      <div className="icon-img">
                        <img src="images/risk.png" alt="risk" />
                      </div>
                      <h3>Risk Management</h3>
                      <p>The blockchain reduces the risk of transactional manipulations due to the distributed, permissioned, and incorruptible property of the technology.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blockchain Help Section Exit*/}
            {/* Blockchain Business Section */}
            <div className="section-block">
              <div className="container">
                <div className="section-heading center-holder">
                  <h3>Why Develop Blockchain For Business?</h3>
                  {/* <div class="section-heading-line"></div> */}
                </div>
                <div className="timeline mt-50">
                  <div className="timeline-row row mb-5">
                    {/* Timeline block starts */}
                    <div className="col-md-6">
                      <div className="timeline-block d-flex">
                        <div className="timeline-icon"><img src="images/blockchain.svg" alt="blockchain" /></div>
                        <div className="timeline-content">
                          <h4 className="timeline-tit">Decentralized</h4>
                          <p className="timeline-txt">The distributed ledger enables a shared system of records among business network members. This eliminates the need for reconciliation of different ledgers.</p>
                        </div>
                      </div>
                    </div>
                    {/* Timeline block ends */}
                    {/* Timeline block starts */}
                    <div className="col-md-6">
                      <div className="timeline-block d-flex">
                        <div className="timeline-icon"><img src="images/key.svg" alt="key" /></div>
                        <div className="timeline-content">
                          <h4 className="timeline-tit">Permissioned</h4>
                          <p className="timeline-txt">The members on the blockchain network will have access privileges and the information can be shared with them on a need to know basis.</p>
                        </div>
                      </div>
                    </div>
                    {/* Timeline block ends */}
                  </div>
                  <div className="timeline-row row">
                    {/* Timeline block starts */}
                    <div className="col-md-6">
                      <div className="timeline-block d-flex">
                        <div className="timeline-icon"><img src="images/bribe.svg" alt="bribe" /></div>
                        <div className="timeline-content">
                          <h4 className="timeline-tit">Incorruptible</h4>
                          <p className="timeline-txt">The consensus is required from the members on the network. In addition, the transactions are permanently recorded. Even system administrators cannot delete the transactions.</p>
                        </div>
                      </div>
                    </div>
                    {/* Timeline block ends */}
                    {/* Timeline block starts */}
                    <div className="col-md-6">
                      <div className="timeline-block d-flex">
                        <div className="timeline-icon"><img src="images/money.svg" alt="money" /></div>
                        <div className="timeline-content">
                          <h4 className="timeline-tit">Cost Reduction</h4>
                          <p className="timeline-txt">It is estimated that blockchain can reduce operating cost by one-third in the banking payments and other financial infrastructure costs. The cost optimization, as a result, will enhance profits.</p>
                        </div>
                      </div>
                    </div>
                    {/* Timeline block ends */}
                  </div>
                </div>
              </div>
            </div>
            {/* Blockchain Business Section Exit */}
            {/* Blockchain Process Section */}
            <div className="clockprocess">
              <div className="container">
                <div className="section-heading center-holder">
                  <h3>Our Blockchain Development Process</h3>
                </div>
                <div className="row process-content">
                  <p style={{textAlign:"center"}}>As we convert your business ideas into practical applications using blockchain technologies, we focus on putting scalability and reliability as important factors to develop mature products and services. For this, we have a tailored process of developing blockchain solutions keeping industry changes and new development standards in mind.
</p>
               <div className='col-md-3'></div>
                  <div className="col-lg-6 align-self-center mb-4 mb-lg-0">
                    <div className="technology-v3-right">
                      <div className="processimg">
                        <img src="images/block-process.png" alt="block-process" />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'></div>
                </div>
              </div>
            </div>
            {/* Blockchain Process Section Exit*/}
            <Footer />
          </div>{/* #page */}
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
        </DocumentMeta>
      </>
    );
  }
};