import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DocumentMeta from 'react-document-meta';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default class Nft_development extends Component {

  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
   }
  render() {
    const meta = {
      title: 'NFT Marketplace Development | Build Your NFT Marketplace | Espsofttech',
      description: ' Espsofttech is a prominent NFT Marketplace Development Company that delivers the finest NFT Marketplace Development Services. Create Your Own NFT Marketplace platforms, Instantly With The Help Of Our Blockchain Experts!',
      canonical: 'https://espsofttech.com/Nft_development',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'nft marketplace development,  build your nft marketplace,  nft marketplace development company,  nft marketplace development service,s  nft marketplace Solutions  '
        }
      }
    }
    return (
      <>
       <DocumentMeta {...meta}>
        <div id="page" className="site">
          <div id="particles-js" />
          <Header />
          <div id="content" className="site-content">
            <section className='nft-background all-bg'>
              <div className='container-fluid p-0'>
                <div class="elementor-background-overlay"></div>
                <div className='portfolio-head'>
                  <h1>NFT Development</h1>
                  <p>Use the NFT marketplaces to auction your artwork with Espsofttech NFT development initiative.</p>
                </div>
              </div>
            </section>
            <section className="service-page">
              <div className="container">
                <div className="space-55" />
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                      <div className="icon-main"><img src='images/icons/nfts/nft.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Token Development Platform</h5>
                        <p className="transform">To digital assets NFT development platform allows tokenization of collectibles. </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/art.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Art Tokenization Platform</h5>
                        <p className="transform">In NFT art tokenization the artists play a vital role with their signature formations.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/marketplace.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Marketplace Development</h5>
                        <p className="transform">For Business Expansion NFT marketplace is a money making niche in the world of crypto.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/game.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Gaming Platform Development</h5>
                        <p className="transform">Exclusive game play is offered in the virtual games with exceptional.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg6 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/sneaker.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Fashion Development </h5>
                        <p className="transform">In the fashion industry NFT is famous by its tokenized exclusive accessories.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg7 text-center">
                      <div className="icon-main"><img src='images/icons/nfts/stock.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Lending Development</h5>
                        <p className="transform">Here,by keeping nft token as collateral,NFT act as an asset that permits you to have a loan money for the borrowed money.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg8 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/exchange.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT Exchange </h5>
                        <p className="transform">A myriad of options is offered by our NFT exchange platform that permits users to deal and switch over their NFT tokens.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg9 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/sports.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT For Sports</h5>
                        <p className="transform">One of the most welcome collectibles is sports memorabilia in the market.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg10 text-center ">
                      <div className="icon-main">
                        <img src='images/icons/nfts/sport.png' />
                      </div>
                      <div className="content-box">
                        <h5 className="transform">NFT For Fantasy Sports</h5>
                        <p className="transform">The sorts of games like Fantasy sports are in this you need to bid the players and structure a team and play for actual cash.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                      <div className="icon-main"> <img src='images/icons/nfts/real-estate.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT for the Real Estate</h5>
                        <p className="transform">By virtualizing the lands and building your own, NFT takes real estate to another level.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/stock.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT for Infrastructure Development </h5>
                        <p className="transform">For your property and tokenize it Infrastructure expansion is also a sort of real estate.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/marketplace.png' /></div>
                      <div className="content-box">
                        <h5 className="transform"> P2P Exchange for NFT’s</h5>
                        <p className="transform">Our NFT p2p exchange platform makes the trading of NFTs a beneficial and feasible option as buying and selling.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg4 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/music.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT for Music</h5>
                        <p className="transform">Non- Fungible tokens in music provide an opportunity for the musicians.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/digital.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT for Domains </h5>
                        <p className="transform">Our Decentralized Domain Name Services allow the business to get the deserved spotlight.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="icon-box-s2  s2 border-s1 bg bg6 text-center ">
                      <div className="icon-main"><img src='images/icons/nfts/video.png' /></div>
                      <div className="content-box">
                        <h5 className="transform">NFT of Videos</h5>
                        <p className="transform">Exclusive video contents like music videos, rare moments, sports moments can be tokenized with NFT
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer />
        </div>{/* #page */}
        </DocumentMeta>
      </>

    )
  }
};