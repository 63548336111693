import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

export default class about extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }

  onChange = (e) => {

    this.setState({
      [e.target.name]: e.target.value
    })
  }


  handleSubmit = async (e) => {
    e.preventDefault()
    await axios({
      method: 'post',
      url: `${config.apiUrl}/insertContact`,
      data: { "name": this.state.name, "email": this.state.email, "message": this.state.message }
    })
      .then(result => {
        if (result.data.success === true) {
          toast.success(result.data.msg)
          this.setState({
            name: '',
            email: '',
            message: '',

          })
        }
        else if (result.data.success === false) {
          toast.error(result.data.msg, {

          });
        }
      }).catch(err => {
      });
  }


  render() {
    return (
      <>
        <div id="page" className="site">

          <Header />
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          {/* <div className='contact-banner'>
          </div> */}
          <section className='contact-background all-bg'>
            <div className='container-fluid p-0'>
              <div class="elementor-background-overlay"></div>
              <div className='portfolio-head'>
                <h1>Contact Us</h1>
                <p>We'd love to hear from you!</p>
              </div>
            </div>
          </section>
          <div id="content" className="site-content">
            <section className="contact-page">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="contact-left">
                      <div className="ot-heading">
                        {/* <span> contact details</span> */}
                        {/* <h2 className="main-heading">Contact us</h2> */}
                      </div>
                      <div className="space-5" />
                      <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                      <div className="contact-info box-style1">
                        <i className="flaticon-world-globe" />
                        <div className="info-text">
                          <h6>Our Address:</h6>
                          <p>601-602, 6th Floor, Princes' Business Skyline, Vijay Nagar, Indore, Madhya Pradesh 452010 </p>
                        </div>
                      </div>
                      <div className="contact-info box-style1">
                        <i className="flaticon-envelope" />
                        <div className="info-text">
                          <h6>Our Mailbox:</h6>
                          <p>business@espsofttech.com</p>
                        </div>
                      </div>
                      <div className="contact-info box-style1">
                        <i className="flaticon-phone-1" />
                        <div className="info-text">
                          <h6>Our Phone:</h6>
                          <p>+91-6267602662</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <form className="wpcf7">
                      <div className="main-form">
                        <h2>Ready to Get Started?</h2>
                        <p className="font14">Your email address will not be published. Required fields are marked *</p>
                        <p>
                          <input type="text" name="name" value={this.state.name} onChange={this.onChange} aria-invalid="false" placeholder="Your Name *" required />
                        </p>
                        <p>
                          <input type="email" name="email" value={this.state.email} onChange={this.onChange} aria-invalid="false" placeholder="Your Email *" required />
                        </p>
                        <p>
                          <textarea name="message" value={this.state.message} onChange={this.onChange} cols={40} rows={10} className aria-invalid="false" placeholder="Message..." required defaultValue={""} />
                        </p>
                        <p><button type="submit" style={{cursor:!this.state.name || !this.state.email || !this.state.message?'not-allowed':''}} disabled={!this.state.name || !this.state.email || !this.state.message} onClick={this.handleSubmit} className="octf-btn octf-btn-light">Send Message</button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            <div className="no-padding">
              {/* <div className="map" style={{filter:'unset'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14718.961752081528!2d75.88823949714833!3d22.737887003426337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd540395f9e9%3A0xa040bded79e76b0d!2sVirtual%20Coworks%20-%20Coworking%20Space%20in%20Indore!5e0!3m2!1sen!2sin!4v1650020099618!5m2!1sen!2sin" height={500} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} />
                

              </div> */}
              <div className="map" style={{filter:'unset'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7056.550314517112!2d75.88904218611826!3d22.74796387874906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd22b0991799%3A0xb24121304045cbc3!2sPrinces&#39;%20Business%20Skyline!5e0!3m2!1sen!2sin!4v1722427781345!5m2!1sen!2sin" height={500} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} />
                
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7056.550314517112!2d75.88904218611826!3d22.74796387874906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd22b0991799%3A0xb24121304045cbc3!2sPrinces&#39;%20Business%20Skyline!5e0!3m2!1sen!2sin!4v1722427781345!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" height={500} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0}></iframe> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>{/* #page */}
      </>
      //  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14718.961752081528!2d75.88823949714833!3d22.737887003426337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd540395f9e9%3A0xa040bded79e76b0d!2sVirtual%20Coworks%20-%20Coworking%20Space%20in%20Indore!5e0!3m2!1sen!2sin!4v1650020099618!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    )
  }
}
