import React, { Component } from 'react';
import config from '../config/config';
import { Link } from 'react-router-dom'
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';

export default class Sitemap extends Component {

    constructor(props) {
        super(props)

    }
    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }

    render() {
        return (
            <>
                <Header />
                <section>
                    <div className='sitebanner'>
                      <img src="images/sitemap.png"/>
                      <div className='sitemap'>
                        <h2>Site Map</h2>
                      </div>
                    </div>
                    <div className="container pt-4">
                        <div className="row pb-4">
                            <div className='col-md-12'>
                                <h3 className='map-head'>Home</h3>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                  
                                        <li>
                                            <a href={`${config.baseUrl}About`}> 
                                            About Us</a></li>
                                        <li><a href={`${config.baseUrl}Our_team`}>
                                            Team</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><a href='#ourServices'>
                                            Services</a></li>
                                        <li><a href={`${config.baseUrl}Contact`}>
                                            Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><a href='#experties'>
                                            Expertise</a></li>
                                        <li><a href='#projects'>
                                            Projects</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><a href='#clients'>
                                            Clients</a></li>
                                        <li><a href='#awards'>
                                            Awards</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row pb-4'>
                            <div className='col-md-12'>
                                <h3 className='map-head'>Services</h3>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><Link to={`${config.baseUrl}Blockchain_service`}>
                                            Blockchain Development</Link></li>
                                        <li><Link to={`${config.baseUrl}Nft_development`}>
                                        Nft Development</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><Link to={`${config.baseUrl}Defi_development`}>
                                        Defi Development</Link></li>
                                        <li><Link to={`${config.baseUrl}Web_development`}>
                                        Website Development</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><Link to={`${config.baseUrl}Mobile_development`}>
                                        Mobile Development</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row pb-4'>
                            <div className='col-md-12'>
                                <h3 className='map-head'>Portfolio</h3>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><Link to={`${config.baseUrl}Portfolio_blockchain`}>
                                        Blockchain</Link></li>
                                        <li><Link to={`${config.baseUrl}Portfolio_web`}>
                                        Website </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='sitemap_content'>
                                    <ul className='features-list'>
                                        <li><Link to={`${config.baseUrl}Portfolio_mobile`}>
                                        Mobile</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
