import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';

export default class about extends Component {

  constructor(props) {
    super(props)
 
}

    render() {
        return (
  <>
  
        <div id="page" className="site">
          <div id="particles-js" />
         <Header/>
          <div id="content" className="site-content">
            <section className="portfolio-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <div className="ot-heading">
                      {/* <span>latest case studies</span> */}
                      <h2 className="main-heading text-white">Introduce Our Projects</h2>
                    </div>
                    <div className="space-5" />
                    <p className="text-white">Software development outsourcing is just a tool to achieve business goals. But there is no way<br />to get worthwhile results without cooperation and trust between a client company.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="project-filter-wrapper">
                      <ul className="project_filters">
                        <li><a href="#" data-filter="*" className="selected">All</a></li>
                        <li><a href="#" data-filter=".design">Design</a></li>                       
                        <li><a href="#" data-filter=".development">Development</a></li>                     
                        <li><a href="#" data-filter=".ideas">Ideas</a></li>                     
                        <li><a href="#" data-filter=".technology">Technology</a></li>                       
                      </ul>
                      <div className="projects-grid projects-style-1 projects-col3">
                        <div className="project-item design ideas">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-1.html">
                                <img src="images/projects/720x720/project1-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-1.html">App for Virtual Reality</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Ideas</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item technology development">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-2.html">
                                <img src="images/projects/720x720/project2-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-2.html">Mobile Coin View App</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Technology</a><span>/</span>
                                  <a href="#">Development</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item ideas technology">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-3.html">
                                <img src="images/projects/720x720/project3-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-3.html">Analysis of Security</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Ideas</a><span>/</span>
                                  <a href="#">Technology</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item design development">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-1.html">
                                <img src="images/projects/720x720/project4-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-1.html">eCommerce Website</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Development</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item design ideas">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-2.html">
                                <img src="images/projects/720x720/project5-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-2.html">Responsive Design</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Ideas</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item development ideas">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-3.html">
                                <img src="images/projects/720x720/project6-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-3.html">App for Health</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Development</a><span>/</span>
                                  <a href="#">Ideas</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item design technology">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-1.html">
                                <img src="images/projects/720x720/project7-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-1.html">Basics Project</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Technology</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item technology development">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-2.html">
                                <img src="images/projects/720x720/project8-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-2.html">Social Media App</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Technology</a><span>/</span>
                                  <a href="#">Development</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item design development">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-3.html">
                                <img src="images/projects/720x720/project9-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-3.html">Your New Reality</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Development</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item development ideas">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-1.html">
                                <img src="images/projects/720x720/project10-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-1.html">Immersive Experiencey</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Ideas</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item design technology">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-1.html">
                                <img src="images/projects/720x720/project11-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-1.html">Corporate Website</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Design</a><span>/</span>
                                  <a href="#">Technology</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="project-item technology ideas">
                          <div className="projects-box">
                            <div className="projects-thumbnail">
                              <a href="portfolio-details-3.html">
                                <img src="images/projects/720x720/project12-720x720.jpg" alt="" />
                              </a>
                            </div>
                            <div className="portfolio-info">
                              <a className="overlay" href="portfolio-details-1.html" />
                              <div className="portfolio-info-inner">
                                <h5><a href="portfolio-details-3.html">Crypto App Project</a></h5>
                                <p className="portfolio-cates">
                                  <a href="#">Technology</a><span>/</span>
                                  <a href="#">Ideas</a>
                                </p> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-60" />
                    <div className="ot-button text-center">
                      {/* <a href={`${config.baseUrl}Portfolio_masonry`} className="octf-btn octf-btn-primary">Load More</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        <Footer/>
        </div>{/* #page */}
      );
      </>

)
}
}