import config from "../config/config";
import React, { useState, useEffect } from "react";
import Header from "../documnets/Header";
import Footer from "../documnets/Footer";
import Particle from "./particles";
import Carousel from "react-multi-carousel";
import DocumentMeta from "react-document-meta";
import Modal from "react-bootstrap/Modal";

import Typewriter from "typewriter-effect";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const responsiveTwo = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Landingpage = (props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [announce, setShowAnnounce] = useState(true);
  const handleCloseAnnounce = async () => {
    setShowAnnounce(false);
  };
  useEffect(() => {
    setInterval(() => {
      setDialogOpen(true);
    }, 1500);
  }, []);

  const meta = {
    title:
      "Blockchain Development Company | Blockchain Development Services | Espsofttech",
    description:
      "Best Blockchain Development Company in India - Espsofttech  provides Solana, ICO, NFT, DeFi, Smart Contracts and Blockchain Software development Services.",
    canonical: "https://www.espsofttech.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          " blockchain development company, blockchain technologies, blockchain consulting, smart contract development, blockchain application development, blockchain solutions, fintech application development, blockchain developers ",
      },
    },
  };
  return (
    <>
      {/* {/ ---------Singapore Modal---------- /} */}
      {/* {isDialogOpen == true ? <Modal id="myModal" size='lg' show={announce} className="singapore_modal" dialogClassName="modal-90w" onHide={handleCloseAnnounce}
                aria-labelledby="example-custom-modal-styling-title" centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className='singaporeContent'>
                        <div className='singapore-box'>
                            <a href='https://espsofttech.com/espsofttech_web23_event/' target='_blank' rel="noreferrer">
                                <img src="images/DUBAI.png" />
                            </a>
                        </div>
                    </div >
                </Modal.Body>
            </Modal> : ""} */}
      {/* {/ /} */}
      {/* {/ -------------------- /} */}

      <DocumentMeta {...meta}>
        <div>
          <div id="page" className="site">
            <div id="content" className="site-content">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n               .home_man_banner span.h1 {\n    display: inline-block;\n    font-size: 52px;\n    font-weight: 700;\n    line-height: 58px;\n    margin-bottom: 25px;\n}\n.home_man_banner .h1 span {\n    color: #eb463f;\n    line-height: 50px;\n    display: inline-block;\n}\n            ",
                }}
              />
              <div id="particles-js" />
            </div>
            <div className="col-lg-2 col-sm-2"></div>
          </div>
          <div className="overlayback">
            <section className="mainbnr custom-shape-divider-bottom-1657893749">
              <Header />
              <div className="container">
                <Particle />
                <div className="row pb-4 mt-2">
                  <div className="col-md-6 pt-2">
                    <div className="main-contain pt-3">
                      <Typewriter
                        options={{
                          strings: ["Welcome to", "ESPSofttech Pvt Ltd."],
                          autoStart: true,
                          loop: true,
                          class: "strings",
                        }}
                      />
                      <h1>
                        Blockchain Development Company In India , Services &
                        Solutions Providers
                      </h1>

                      <p className="paragraph-one">
                        We are in the business of developing world quality
                        blockchain-based development services that are readily
                        accessible and highly affordable. Get in touch with us
                        and enrich your business with expertise.
                      </p>
                      <div className="main-button">
                        <button type="button">
                          <a href={`${config.baseUrl}Contact`}>Contact Us</a>
                        </button>
                      </div>
                    </div>

                    <p></p>
                  </div>
                  <div className="col-md-6">
                    <div className="bnrImg">
                      <img src="images/web2.png" alt="Blockchain-banner" />
                    </div>
                  </div>
                </div>
              </div>

              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                  class="shape-fill"
                ></path>
              </svg>
            </section>
          </div>
          {/* Banner new sec */}
          {/* Banner new sec Exit*/}

          {/* ------------------------------welcome section------------------- */}
          <section>
            <div className="container">
              <div className="row ">
                <div className="col-md-3 left-sec">
                  <div className="wel_img">
                    <img src="images/wel3.webp" alt="Our Mission" />
                  </div>
                  <div className="wel_img img2">
                    <img src="images/wel2.webp" alt="Our Vision" />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row p-3">{/* <div>abaut us</div> */}</div>
                  <div className="row">
                    <div className="col-md-4 right-sec">
                      <div className="wel_img">
                        <img src="images/wel1.webp" alt="Our Phiphlosoy" />
                        <div className="circle">
                          <div className="color-circle">
                            <div>
                              <h3>10+</h3>
                              <p>Years experience</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="welcome-text">
                        <h2>Welcome to ESPSofttech</h2>
                        <p>
                          Being an Industry Leading Blockchain development
                          company in India, We transform your business/industry
                          processes into highly secure, scalable and transparent
                          growth with our premium blockchain development
                          services & solutions.
                        </p>
                        <div className="main-button">
                          <a href={`${config.baseUrl}Blockchain_service`}>
                            {" "}
                            <button className="" type="button">
                              More Details
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-md-5 p-0 ">
                    <div className="col-lg-3 col-md-4 col-sm-6 p-0 mb-4 mb-md-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={550} data-time={2000}>
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className>active Clients</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 p-0 mb-md-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={1500} data-time={2000}>
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className>Projects Done</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 p-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={55} data-time={2000}>
                            0
                          </span>
                          <span>+</span>
                        </div>
                        <h6 className>Team Advisors</h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 p-0 text-center text-md-left">
                      <div className="ot-counter v3">
                        <div>
                          <span className="num" data-to={99} data-time={2000}>
                            0
                          </span>
                          <span>%</span>
                        </div>
                        <h6 className>Customer Satisfaction</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* -------------------------------Services---------------------------------------------- */}
          {/* ---------------------------------------------------------------------------------------------- */}

          <section className="service-section bg-light" id="ourServices">
            <div className="container pb-5 pt-md-4">
              <div className="p-md-5">
                <div className="service-area">
                  <h2>Our Services</h2>
                  <p>
                    We are a completely bootstrapped blockchain-based software
                    development company with 10 years of track record under our
                    belt.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="scards">
                        <a href={`${config.baseUrl}Blockchain_service`}>
                          <div className="scard-icon">
                            <img
                              src="images/service-icons/blockchain.png"
                              width="50px"
                            />
                          </div>
                          <h4>Blockchain</h4>
                          <p>Blockchain Development Services </p>
                          <div className="learn-btn">
                            <a href={`${config.baseUrl}Blockchain_service`}>
                              Learn more
                            </a>
                          </div>
                        </a>
                      </div>
                      <div className="scards">
                        <a href={`${config.baseUrl}Nft_development`}>
                          <div className="scard-icon">
                            <img
                              src="images/service-icons/nft.png"
                              width="50px"
                            />
                          </div>
                          <h4>Nft</h4>
                          <p>Nft Development Services</p>
                          <div className="learn-btn">
                            <a href={`${config.baseUrl}Nft_development`}>
                              Learn more
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="one-box">
                        <div className="scards">
                          <a href={`${config.baseUrl}Defi_development`}>
                            <div className="scard-icon">
                              <img
                                src="images/service-icons/defi.png"
                                width="50px"
                              />
                            </div>
                            <h4>Defi</h4>
                            <p>Defi Development Services </p>
                            <div className="learn-btn">
                              <a href={`${config.baseUrl}Defi_development`}>
                                Learn more
                              </a>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mt-3 p-3">
                    <lottie-player
                      src="https://assets1.lottiefiles.com/packages/lf20_fz3azqj8.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "100%", height: "100%" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="one-box">
                        <div className="scards">
                          <a href={`${config.baseUrl}web_development`}>
                            <div className="scard-icon">
                              <img
                                src="images/service-icons/web.png"
                                width="50px"
                              />
                            </div>
                            <h4>Web Application </h4>
                            <p> Web Applications Development </p>
                            <div className="learn-btn">
                              <a href={`${config.baseUrl}web_development`}>
                                Learn more
                              </a>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="scards">
                        <a href={`${config.baseUrl}Mobile_development`}>
                          <div className="scard-icon">
                            <img
                              src="images/service-icons/mobile.png"
                              width="50px"
                            />
                          </div>
                          <h4>Mobile </h4>
                          <p>Mobile Aopplication Development</p>
                          <div className="learn-btn">
                            <a href={`${config.baseUrl}Mobile_development`}>
                              Learn more
                            </a>
                          </div>
                        </a>
                      </div>
                      <div className="scards">
                        <a href={`${config.baseUrl}Blockchain_service`}>
                          <div className="scard-icon">
                            <img
                              src="images/service-icons/binance.png"
                              width="50px"
                            />
                          </div>
                          <h4>Binance</h4>
                          <p>Binance Smart Chain Development</p>
                          <div className="learn-btn">
                            <a href={`${config.baseUrl}Blockchain_service`}>
                              Learn more
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* -------------------------------------------------clients section-----------------------------------------------
---------------------------------------------------------------------------------------------------------------- */}
          <section>
            <div className="container pt-5 pb-5 mt-lg-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="service-area">
                    <h2>Our Clients Reviews</h2>
                    <p>
                      Our clients praise us for our great results, personable
                      service, expert knowledge and on-time delivery. Here are
                      what just a few of them had to say
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-5 pt-5">
                <div className="col-lg-6 pt-md-5 shape-h">
                  <div class="slideshow-container ">
                    <div class="mySlides fades">
                      <img
                        src="images/testimonial/joseph.jpg"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div class="mySlides fades">
                      <img
                        src="images/testimonial/barry.png"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div class="mySlides fades">
                      <img
                        src="images/testimonial/breno.jpg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div class="mySlides fades">
                      <img
                        src="images/testimonial/prakash.jpg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div class="mySlides fades">
                      <img
                        src="images/testimonial/osman.jpg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div class="side-img-1 client-img">
                      <img src="images/testimonial/joseph.jpg" alt="" />
                    </div>
                    <div class="side-img-2 client-img">
                      <img src="images/testimonial/barry.png" alt="" />
                    </div>
                    <div class="side-img-3 client-img">
                      <img src="images/testimonial/breno.jpg" alt="" />
                    </div>
                    <div class="side-img-4 client-img">
                      <img src="images/testimonial/prakash.jpg" alt="" />
                    </div>
                    <div class="side-img-5 client-img">
                      <img src="images/testimonial/osman.jpg" alt="" />
                    </div>
                    <div class="border-s1"></div>
                    <div class="border-s2"></div>
                    <div class="border-s3"></div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="clints_details">
                    <div class="slideshow-container">
                      <div class="mySlides1 slides">
                        <h2>Joseph</h2>
                        <p>
                          " We have established a great working relationship
                          with Rahul. Our project has become more complex and
                          they have accommodated us and we are still finalizing
                          other elements within our project with Rahul. Rahul
                          was recommended via freelancer on site recruiter and
                          we have been very happy with the work completed."
                        </p>
                        {/* <h4></h4> */}
                      </div>
                      <div class="mySlides1 slides">
                        <h2>Barry M</h2>
                        <p>
                          " Rahul and his team are very professional. Delivered
                          on time and communication was excellent and
                          understanding of what was required was perfect. They
                          understand very well what needs to be deliver. His
                          team is having expertise in Flutter based mobile
                          development specially in Hybrid mobile application
                          development. Would recommend everyone to hire him."
                        </p>
                        {/* <h4>Barry M</h4> */}
                      </div>
                      <div class="mySlides1 slides">
                        <h2>Breno L</h2>
                        <p>
                          " Rahul and team have created me exactly what I was
                          dreaming. As promised. It did take a bit longer than
                          I’d had hopped, but I was pretty picky as well. I
                          would recommend this team for quality and price and
                          would love to work with them again."
                        </p>
                        {/* <h4>Breno L</h4> */}
                      </div>
                      <div class="mySlides1 slides">
                        <h2>Prakash G</h2>
                        <p>
                          "Rahul is the best freelancer have ever come across.
                          He delivers quality work and has a lot of experience
                          in, communication, actualization of work and most
                          importantly he understands and delivers 100%. I will
                          love to work with him in the future projects."
                        </p>
                        {/* <h4>Prakash G</h4> */}
                      </div>
                      <div class="mySlides1 ">
                        <h2>Osman M</h2>
                        <p>
                          "If you are looking for professionals to have your
                          project done so, you are in the correct place. Rahul
                          is a very professional leader. He put the customer on
                          the top of his priority. He provides high-quality
                          service and quick response to customer needs. Also, he
                          keeps the customer updated with every single step done
                          in the project. The team is professional and able to
                          deal with the complexity of projects requirements. The
                          cost is very fair and reasonable. FIVE STARS and
                          strongly recommend them."
                        </p>
                        {/* <h4>Osman M</h4> */}
                      </div>
                    </div>
                    <div className="arrow-btn">
                      <a class="prev" id="prevBtn">
                        ❮
                      </a>
                      <a class="next" id="nextBtn">
                        ❯
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="left-shape"></div>
          </section>
          {/* -------------------------------our work section---------------------------------------------- */}
          {/* ---------------------------------------------------------------------------------------------- */}
          {/* <div className='container'>
        <div className='p-md-5 '>
          <div className='service-area'>
            <h2>Our Expertise</h2>
            <p>We are a completely bootstrapped blockchain-based software development company with 09 years of track record under our belt.</p>
          </div>
        </div>
      </div>
      <section className='work_section' id='experties'>
        <div className='what_our_work'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='worksection'>
                  <Slider {...settings}>

                    <div className='row d-flex ' style={{ backgroundColor: "#fff" }}>
                      <div className='col-lg-6'>

                        <div className='techinfo-img'>
                          <div class="div-block-11"></div>
                          <div className='techinfo-img_box'>
                            <img src='images/work/web_mob2.png' />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12'>
                        <div className='techinfo-text'>
                          <h2>Institutional Cryptocurrency Lending Platform</h2>
                          <p>Let’s say you are a software house that prides itself on offering world-class technological expertise and IT development for blockchain, cryptocurrency, and telecommunication companies at a reasonable price and on time. And you like challenges. Then, one day, you are approached by a cryptocurrency lending company, with a mission of creating a financial infrastructure that encourages</p>
                          <ul>
                            <li><span>60k</span> active users</li>
                            <li><span>4 years</span> and ongoing collaboration</li>
                            <li><span>2000</span> tickets done and counting</li>
                          </ul>
                          <div className='detail-info'>
                            <a href={`${config.baseUrl}Portfolio_blockchain`} class="orange-button mt-auto mb-60 w-inline-block">
                              <div class="orange-button-text">See More Projects</div>

                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row d-flex ' style={{ backgroundColor: "#fff" }}>
                      <div className='col-md-6'>

                        <div className='techinfo-img'>
                          <div class="div-block-11"></div>
                          <div className='techinfo-img_box'>
                            <img src='images/work/web_tab.png' />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='techinfo-text'>
                          <h2>Institutional Cryptocurrency Lending Platform</h2>
                          <p>Let’s say you are a software house that prides itself on offering world-class technological expertise and IT development for blockchain, cryptocurrency, and telecommunication companies at a reasonable price and on time. And you like challenges. Then, one day, you are approached by a cryptocurrency lending company, with a mission of creating a financial infrastructure that encourages</p>
                          <ul>
                            <li><span>60k</span> active users</li>
                            <li><span>4 years</span> and ongoing collaboration</li>
                            <li><span>2000</span>  tickets done and counting</li>
                          </ul>
                          <div className='detail-info'>
                            <a href={`${config.baseUrl}Portfolio_blockchain`} class="orange-button mt-auto mb-60 w-inline-block">
                              <div class="orange-button-text">See More Projects</div>

                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row d-flex'>
                      <div className='col-md-6'>

                        <div className='techinfo-img'>
                          <div class="div-block-11"></div>
                          <div className='techinfo-img_box'>
                            <img src="images/work/mobole-mockup.png" className='' alt="" />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='techinfo-text'>
                          <h2>Full Stack Support to Mobile Virtual Network Operator</h2>
                          <p>The age of digital services, on-demand business’ and eCommerce are growing rapidly and moving all physical assets toward the Net. Now, the only thing which can make a lot of trouble for you is a lack of access to the Internet because of an account without any cash on in.  Mobile Vikings, a mobile virtual network operator, noticed this market niche and as a result entered the Polish telecom...</p>
                          <ul>
                            <li><span>60k</span> active users</li>
                            <li><span>4 years</span> and ongoing collaboration</li>
                            <li><span>2000</span>  tickets done and counting</li>
                          </ul>
                          <div className='detail-info'>
                            <a href={`${config.baseUrl}Portfolio_mobile`} class="orange-button mt-auto mb-60 w-inline-block">
                              <div class="orange-button-text">See More Projects</div>

                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row d-flex ' style={{ backgroundColor: "#fff" }}>
                      <div className='col-md-6'>

                        <div className='techinfo-img'>
                          <div class="div-block-11"></div>
                          <div className='techinfo-img_box'>
                            <img src='images/work/mob_web.png' />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='techinfo-text'>
                          <h2>Platform for Designing Uniquely Personalized</h2>
                          <p>Perfumes, scented oils and other fragrances have been worn by people since early civilization. The beauty market is one of the most creative industries having to compete on a global market delivering every year new fragrances so as to stay ahead of the crowd. Today, each of us can can create a perfume or cologne of our own! An American startup called Scent Trunk gives you a set of...</p>
                          <ul>
                            <li><span>60k</span> active users</li>
                            <li><span>4 years</span> and ongoing collaboration</li>
                            <li><span>2000</span>  tickets done and counting</li>
                          </ul>
                          <div className='detail-info'>
                            <a href={`${config.baseUrl}Portfolio_web`} class="orange-button mt-auto mb-60 w-inline-block">
                              <div class="orange-button-text">See More Projects</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
          <section className="technology-v3 bg-light" id="projects">
            <div className="container">
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-md-8 text-center">
                  <div className="service-area">
                    <h2>Our Proudest Achievements</h2>
                    <p>
                      Explore how we think, perform and ensure client
                      satisfaction.
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
            <div className="space-10" />
            <div className="container">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsiveTwo}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                customTransition="all 1s"
                transitionDuration={1000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="#">
                        <img
                          src="images/project3.png"
                          class=""
                          alt="Yuse-token"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://yusetoken.io/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://yusetoken.io/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://yusetoken.io/" target="_blank">
                            Yuse Token
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="#">
                        <img
                          src="images/project4.png"
                          class=""
                          alt="Odd-fellows"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://oddfellowsnft.com/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://oddfellowsnft.com/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://oddfellowsnft.com/" target="_blank">
                            Odd Fellows NFTs
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/project6.png"
                          class=""
                          alt="Art fungible"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://artfungible.io/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://artfungible.io/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://artfungible.io/" target="_blank">
                            Art Fungible
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| LIKEBIT ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/likbit.png"
                          class=""
                          alt="Likebit"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://www.likebittoken.com/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://www.likebittoken.com/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://www.likebittoken.com/" target="_blank">
                            Likebit
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| INFIMULTICHAIN ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/infiMultichain.png"
                          class=""
                          alt="INFI MultiChai"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://infimultichain.com/exch/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://infimultichain.com/exch/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://infimultichain.com/exch/" target="_blank">
                            INFI MultiChain
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| RAFFLE GAME ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/dappwin.png"
                          class=""
                          alt="dappwin"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://dappwin.io/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://dappwin.io/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://dappwin.io/" target="_blank">
                            DAPPWIN.IO
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| RAFFLE GAME ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/purpleswap.png"
                          class=""
                          alt="purpleswap"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://purpleswap.io/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://purpleswap.io/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://purpleswap.io/" target="_blank">
                            purpleswap
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| HI5 ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/hi5.png"
                          class=""
                          alt="hi5space"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://hi5space.com/"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://hi5space.com/" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://hi5space.com/" target="_blank">
                            hi5space
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| SOCIAL SYSTEM ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/social.png"
                          class=""
                          alt="social system"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://play.google.com/store/apps/details?id=com.social.social_system&pli=1"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://play.google.com/store/apps/details?id=com.social.social_system&pli=1" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://play.google.com/store/apps/details?id=com.social.social_system&pli=1" target="_blank">
                            Teacher Socials
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| STEP MINT ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/stepmint.png"
                          class=""
                          alt="Stepmint"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://play.google.com/store/search?q=step%20mint&c=apps&hl=en_IN&gl=US"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://play.google.com/store/search?q=step%20mint&c=apps&hl=en_IN&gl=US" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://play.google.com/store/search?q=step%20mint&c=apps&hl=en_IN&gl=US" target="_blank">
                            Stepmint
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| PLATINX ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/platinx.png"
                          class=""
                          alt="Platinx"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://apps.apple.com/in/app/platinx/id1637866256"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://apps.apple.com/in/app/platinx/id1637866256" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://apps.apple.com/in/app/platinx/id1637866256" target="_blank">
                            Platinx
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                {/* ------------------|| Atujii ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/atujii.png"
                          class=""
                          alt="Atujii"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://apps.apple.com/ua/app/atujii/id1597234314"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://apps.apple.com/ua/app/atujii/id1597234314" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://apps.apple.com/ua/app/atujii/id1597234314" target="_blank">
                            Atujii
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------------------|| EverETH Wallet App ||----------------- */}

                <div class="project-item projects-style-2">
                  <div class="projects-box">
                    <div class="projects-thumbnail">
                      <a href="">
                        <img
                          src="images/everethwallet.png"
                          class=""
                          alt="EverETH Wallet App"
                        />
                        {/* <span class="overlay"></span> */}
                      </a>
                    </div>
                    <div class="portfolio-info ">
                      <div class="portfolio-info-inner">
                        <a
                          class="btn-link"
                          href="https://play.google.com/store/apps/details?id=com.ever_eth"
                          target="_blank"
                        >
                          <i class="flaticon-right-arrow-1"></i>
                        </a>
                        <h5>
                          <a href="https://play.google.com/store/apps/details?id=com.ever_eth" target="_blank">
                            Live URL
                          </a>
                        </h5>
                        <p class="portfolio-cates">
                          <a href="https://play.google.com/store/apps/details?id=com.ever_eth" target="_blank">
                            EverETH Wallet App
                          </a>
                          {/* <a href="#">Ideas</a> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </Carousel>
              ;
            </div>
          </section>
          <section>
            <div class="container pt-5">
              <div className="service-area">
                <h2> Event Participation </h2>
                {/* <p>Explore how we think, perform and ensure client satisfaction.</p> */}
              </div>
              <div class="row no-gutters pt-5">
                <div class="filtering col-sm-12 text-center">
                  <span data-filter="*" class="active">
                    All
                  </span>
                  <span data-filter=".architecture" class="">
                    Singapore
                  </span>
                  <span data-filter=".decor" class="">
                    Mumbai
                  </span>
                  <span data-filter=".interior" class="">
                    Delhi
                  </span>
                  <span data-filter=".dubai" class="">
                    Dubai
                  </span>
                </div>
                <div class="col-12 text-center w-100">
                  <div class="grid form-row gallery text-center">
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item interior">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/1.jpeg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai1.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai2.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai3.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai4.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai5.jpg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item dubai">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/dubai6.jpg" alt="..." />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 mb-2 grid-item decor interior">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/2.jpeg" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item architecture">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/6.jpeg" alt="..." />
                        </div>
                        {/* <div class="portfolio-overlay">
                      <div class="portfolio-content">
                        <a class="popimg ml-0" href="#">
                          <i class="ti-zoom-in display-24 display-md-23 display-lg-22 display-xl-20"></i>
                        </a>
                        <h4>Modern Villa in Belgium</h4>
                        <p>[Architecture]</p>
                      </div>
                    </div> */}
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item interior">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/img13.jpeg" alt="..." />
                        </div>
                        {/* <div class="portfolio-overlay">
                      <div class="portfolio-content">
                        <a class="popimg ml-0" href="#">
                          <i class="ti-zoom-in display-24 display-md-23 display-lg-22 display-xl-20"></i>
                        </a>
                        <h4>Kitchen for Small family</h4>
                        <p>[Interior]</p>
                      </div>
                    </div> */}
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb-2 grid-item architecture">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/19.jpg" alt="..." />
                        </div>
                        {/* <div class="portfolio-overlay">
                      <div class="portfolio-content">
                        <a class="popimg ml-0" href="#">
                          <i class="ti-zoom-in display-24 display-md-23 display-lg-22 display-xl-20"></i>
                        </a>
                        <h4>Art Family Residence</h4>
                        <p>[Architecture]</p>
                      </div>
                    </div> */}
                      </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 grid-item architecture">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img src="images/gallery/img18.jpg" alt="..." />
                        </div>
                        {/* <div class="portfolio-overlay">
                      <div class="portfolio-content">
                        <a class="popimg ml-0" href="#">
                          <i class="ti-zoom-in display-24 display-md-23 display-lg-22 display-xl-20"></i>
                        </a>
                        <h4>Loft Kitchen Interior</h4>
                        <p>[Architecture]</p>
                      </div>
                    </div> */}
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 grid-item grid-item interior">
                      <div class="portfolio-wrapper">
                        <div class="portfolio-image">
                          <img
                            src="images/gallery/img11.jpg"
                            alt="..."
                            className="event10"
                          />
                        </div>
                        {/* <div class="portfolio-overlay">
                      <div class="portfolio-content">
                        <a class="popimg ml-0" href="#">
                          <i class="ti-zoom-in display-24 display-md-23 display-lg-22 display-xl-20"></i>
                        </a>
                        <h4>Loft Kitchen Interior</h4>
                        <p>[Architecture]</p>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="awards" id="awards">
            <div className="container">
              <div className="row">
                <div className="service-area w-100">
                  <h2>Awards & Recognition</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award1.png" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award2.png" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award3.png" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award4.png" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award5.png" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="award-img">
                    <img src="images/award2.png" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </DocumentMeta>
    </>
  );
};

export default Landingpage;
