import config from '../config/config';
import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DocumentMeta from 'react-document-meta';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default class Defi_development extends Component {

  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
   }
  render() {
    const meta = {
      title: 'DeFi development services | defi development solutions | Espsofttech',
      description: 'Take your financial operations to the next level by integrating a solution from the world of decentralized finance (DeFi).',
      canonical: 'https://espsofttech.com/Defi_development',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'defi development, development services, defi solution, defi company'
        }
      }
    }
    return (
      <>
           <DocumentMeta {...meta}>
        <div>
          <div id="page" className="site">
            <div id="particles-js" />
            <Header />
            <div id="content" className="site-content">
              <section className='defi-background all-bg'>
                <div className='container-fluid p-0'>
                  <div class="elementor-background-overlay"></div>
                  <div className='portfolio-head'>
                    <h1>Defi Development</h1>
                    <p>Thrive in the Emerging Decentralized Finance World with Our DeFi Development Solutions.</p>
                  </div>
                </div>
              </section>
              <section className="service-page">
                <div className="container">
                  <div className="space-55" />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><span className="flaticon-app" /></div>
                        <div className="content-box">
                          <h5 className="transform">Defi DAPP</h5>
                          <p className="transform">Decentralized applications—also known as "dApps" or "dapps"—are digital applications that run on a blockchain network of computers instead of relying on a single computer. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg2 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/finance.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Open Finance</h5>
                          <p className="transform">Open finance refers to the trend of FinTech companies using blockchain technology and API-based integration with banking services in the traditional financial system.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg3 text-center ">
                        <div className="icon-main"><img src='images/icons/CryptoWallet.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Wallet</h5>
                          <p className="transform">DeFi Wallet is a user-custodied wallet where you are the custodian. This means you get full access and control of your crypto private keys.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg5 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/Yearn.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Yearn Finance</h5>
                          <p className="transform">Yearn Finance (YFI) is a suite of products in Decentralized Finance (DeFi) that provides lending aggregation, yield generation and insurance on the Ethereum blockchain. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg6 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/exchange.png' /></div>
                        <div className="content-box">
                          <h5 className="transform"> DeFI Exchange</h5>
                          <p className="transform">Decentralized finance (DeFi) offers financial instruments without relying on intermediaries such as brokerages, exchanges, or banks. Instead, it uses smart contracts on a blockchain.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg hbg text-center">
                        <div className="icon-main"><img src="images/hyperledger.png" width="40px" /></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Insurance</h5>
                          <p className="transform">DeFi insurance refers to insuring yourself, or 'buying coverage', against losses caused by events in the DeFi industry.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/landing.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Lending Platform</h5>
                          <p className="transform">Crypto lending refers to a type of Decentralized Finance that allows investors to lend their cryptocurrencies to different borrowers. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><span class="flaticon-report-1"></span></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Marketing</h5>
                          <p className="transform">Decentralized finance or DeFi is a financial system that reimagines financial transactions by removing intermediaries and is based on blockchain technology, typically Ethereum. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main">
                          <img src="images/crypto-wallet.png" width="40px" />
                        </div>
                        <div className="content-box">
                          <h5 className="transform">Defi Protocol AAVE</h5>
                          <p className="transform">Aave is a decentralized finance (DeFi) protocol that enables the borrowing and lending of cryptocurrency, including so-called flash loans. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/smart-contracts.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Smart Contract</h5>
                          <p className="transform">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/token.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Token</h5>
                          <p className="transform">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/farm.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">DeFi Yield Farming</h5>
                          <p className="transform">Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/defi/stak.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Staking Platform</h5>
                          <p className="transform">Staking cryptocurrencies is a process that involves committing your crypto assets to support a blockchain network and confirm transactions.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/Bitcoin-Digital.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">1inches Exchange</h5>
                          <p className="transform">1inch is an exchange aggregator that scans decentralized exchanges to find the lowest cryptocurrency prices for traders, and is powered by its 1INCH utility and governance token.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center ">
                        <div className="icon-main"><img src='images/icons/cryptoapp.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Crypto Synthetic Assets Development</h5>
                          <p className="transform">Crypto Synthetic Assets support tokens in the DeFi landscape such as stable coins.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              
            </div>
            <Footer />
          </div>{/* #page */}
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
        </DocumentMeta>
      </>

    )
  }
};