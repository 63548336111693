import React, { Component } from 'react';
import Header from '../documnets/Header';
import Footer from '../documnets/Footer';
import DocumentMeta from 'react-document-meta';
const responsiveTwo = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default class Mobile_development1 extends Component {

  constructor(props) {
    super(props)

  }
  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
   }
  render() {
    const meta = {
      title: 'Mobile Application Development Services | Mobile App Development Company in India | Mobile App Development Services |',
      description: 'Leading mobile app development company, Espsofttech is capable of scaling your business. Avail of our Mobile App Development Services for assured growth.',
      canonical: 'https://espsofttech.com/Mobile_development',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Mobile app development, Android application development, IOS application development, Mobile application development, Native App development, Cross Platform App development, React Native Application, Flutter Application development'
        }
      }
    }
    return (
      <>
     <DocumentMeta {...meta}>
        <div>
          <div id="page" className="site">
            <div id="particles-js" />
            <Header />
            <div id="content" className="site-content">
              <section className='mobile-background all-bg'>
                <div className='container-fluid p-0'>
                  <div class="elementor-background-overlay"></div>
                  <div className='portfolio-head'>
                    <h1>Mobile Development</h1>
                    <p>Espsofttech mobile development team has exceptional experience in creating native, hybrid, and cross-platform mobile apps.</p>
                  </div>
                </div>
              </section>
              <section className="mobile-app pt-5 mt-5">
                <div className='container'>
                  <div className='row'>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><img src='images/icons/mobile/android.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Android App Devepment</h5>
                          <p className="transform">High performance, effective, and highly reliable custom Android apps. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><img src='images/icons/mobile/ios.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">IOS App Devepment</h5>
                          <p className="transform">Develop next-gen iOS apps that are quality tested for perfection with future-forward iOS application development services to help you get ahead of your competition.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><img src='images/icons/mobile/flutter.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Flutter App DevepmentP</h5>
                          <p className="transform">We streamline your business with the next-gen mobile app using Flutter app development. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><img src='images/icons/mobile/iconic.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">Ionic App Devepment</h5>
                          <p className="transform">
                            Being an Ionic app Development Company, we build interactive and incredible hybrid & cross-platform applications for iOS and Android with efficiency. </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="icon-box-s2  s2 border-s1 bg bg1 text-center  pb-60">
                        <div className="icon-main"><img src='images/icons/mobile/react.png' /></div>
                        <div className="content-box">
                          <h5 className="transform">React Native Devepment</h5>
                          <p className="transform">We Build the native Android Apps using React Native library. Experience high-quality React Native app development services by Espsopfttech and leverage cross-platform apps for business.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container pt-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="ot-heading">
                        {/* <span> business benefits</span> */}
                        <h2 className="main-heading text-white">Mobile Apps Benefits</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 align-self-center">
                      <div className="icon-box-s2 s3 app-benefits-left" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-laptop" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Software as a Service</h5>
                          <p className="text-white">51% of smartphone users have discovered a new company or product.</p>
                        </div>
                      </div>
                      <div className="icon-box-s2 s3 app-benefits-left ml-0 mr-0" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-seo-and-web" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Internet of Things</h5>
                          <p className="text-white">Move your SaaS products to mobile, Companies with a professional mobile.</p>
                        </div>
                      </div>
                      <div className="icon-box-s2 s3 app-benefits-left" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-process" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Gambling &amp; Betting</h5>
                          <p className="text-white">Develop a custom mobile app to thrive in a mobile market worth over $100.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="app-benefits-img text-center p-5 m-2">
                        <img src="images/mobile.png" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-3 align-self-center" >
                      <div className="icon-box-s2 s1 app-benefits-right" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-data-1" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Social Media </h5>
                          <p className="text-white">80% of time users spend in social mediafrom their mobile devices.</p>
                        </div>
                      </div>
                      <div className="icon-box-s2 s1 app-benefits-right ml-0 mr-0" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-code-2" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Business Management</h5>
                          <p className="text-white">65% of sales representatives have achieved their quotas by adopting.</p>
                        </div>
                      </div>
                      <div className="icon-box-s2 s1 app-benefits-right" style={{ minHeight: "100px" }}>
                        <div className="icon-main"><span className="flaticon-report" /></div>
                        <div className="content-box">
                          <h5 className="text-white">Trading Systems</h5>
                          <p className="text-white">We provide top-tier mobile app development services for brokers.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <section className="counter-v5">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-3 col-md-6 no-padding">
                    <img src="images/fun-fact1.jpg" alt="" className="img-full" />
                  </div>
                  <div className="col-xl-3 col-md-6 no-padding align-self-center">
                    <div className="ot-counter2">
                      <div className="s-num">
                        <span className="num" data-to={15} data-time={2000}>0</span><span>+</span>
                      </div>
                      <h6>Countries Worldwide</h6>                    
                      <p>To succeed, every software solution must be deeply integrated into the existing tech environment...</p>  
                      <a href={`${config.baseUrl}About`} className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                      <div className="b-num">15+</div>    
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 order-md-last no-padding">
                    <img src="images/fun-fact2.jpg" alt="" className="img-full" />
                  </div>
                  <div className="col-xl-3 col-md-6 no-padding order-xl-last align-self-center">
                    <div className="ot-counter2">
                      <div className="s-num">
                        <span className="num" data-to={23} data-time={2000}>0</span><span>k</span>
                      </div>
                      <h6>Happy Customers</h6>                    
                      <p>To succeed, every software solution must be deeply integrated into the existing tech environment...</p>  
                      <a href={`${config.baseUrl}About`} className="btn-details"><i className="flaticon-right-arrow-1" /> LEARN MORE</a>
                      <div className="b-num">23k</div>    
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
              {/* <section className="app-offer">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <img src="images/apple-w.png" alt="" />
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="right-about-v4">
                        <div className="ot-heading">
                          <h2 className="main-heading text-white">Your Partner for <br />Software Innovation</h2>
                        </div>
                        <div className="space-5" />
                        <p className="text-white">Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                        <p><em className="text-dark"><strong>We can help to maintain and modernize your IT infrastructure and solve various infrastructure-specific issues a business may face.</strong></em></p>
                        <div className="video-popup style-2">
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
            <Footer />
          </div>{/* #page */}
          <a id="back-to-top" href="#" className="show"><i className="flaticon-up-arrow" /></a>
        </div>
        </DocumentMeta>
      </>

    )
  }
};